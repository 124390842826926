<template>
  <info-box
    :title="$t('main.parkingEligibiltyInformation.title')"
    :text="$t('main.parkingEligibiltyInformation.text', { parkingSiteName })"
    bg-color="blue"
    icon-name="info-large"
  />
</template>

<script>
import infoBox from '@/components/shared/information/infoBox';

export default {
  components: { infoBox },
  name: 'ParkingEligibilityInformation',
  props: {
    parkingSiteName: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.eligibility-container {
  background-color: var(--color-blue-50);
  border-radius: 6px;
  padding: 20px;
  margin: 16px 0;

  .eligibility-content {
    display: flex;
    gap: 18px;
    align-items: center;

    .info-icon {
      padding: 8px;
    }

    .text-content {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>