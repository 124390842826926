<template>
  <modal
    ref="modal"
    :name="name"
    class="dialog"
    :width="width"
    height="auto"
    :pivot-y="0.35"
    :click-to-close="canClickToClose"
    @before-open="beforeOpened"
    @before-close="forceClose"
  >
    <n-theme :type="theme">
      <div class="container">
        <div
          v-if="params.closeButton"
          class="close-button"
          @click="close"
        >
          <n-icon name="close-dialog" size="md" color="close-gray" />
        </div>
        <div>
          <div
            v-if="iconComponent"
            class="icon-container"
          >
            <component
              :is="iconComponent"
              v-bind="iconProps"
              :component="iconComponent"
            />
          </div>
          <div 
            v-if="headerPicture"
            class="header-container"
          >
            <img
              :src="headerPicture" 
              class="header-picture"
            >
          </div>
          <n-layout
            :spacing-x="false"
            :spacing-y="false"
            class="content"
          >
            <n-text
              preset="header"
              class="row title"
            >
              <template v-if="typeof params.title === 'string'">
                <n-text-model 
                  size="lg" 
                  weight="medium"
                >
                  {{ params.title }}
                </n-text-model>
              </template>
              <template v-else-if="Array.isArray(params.title)">
                <n-text-model
                  v-for="(part, index) in params.title"
                  :key="index"
                  :style="{ color: part.color }"
                  size="lg"
                  weight="medium"
                >
                  {{ part.text }}
                </n-text-model>
              </template>
            </n-text>
            <n-text
              :preset="textPreset"
              class="color-neutral-700 row message"
            >
              <span v-if="params.text">{{ params.text }}</span>
              <slot v-else />
            </n-text>
          </n-layout>

          <n-layout
            v-if="toggle.enabled"
            :spacing-y="false"
            :spacing-bottom="true"
          >
            <n-switch
              v-model="toggle.toggled"
              class="span-6"
            >
              {{ toggle.text }}
            </n-switch>
          </n-layout>

          <div class="button-container">
            <n-layout
              class="button-content"
              :class="buttonsInline ? 'inline-buttons' : ''"
              :spacing-y="false"
              :spacing-bottom="true"
            >
              <n-button
                v-if="cancel"
                :color="cancelButton?.color ?? params.color"
                size="md"
                type="outlined"
                inverted
                :text-uppercase="false"
                @click="cancelAction($event)"
              >
                {{ cancelButton.text }}
              </n-button>

              <n-button
                v-if="successButton && !successButton.hidden"
                :color="successButton?.color ?? params.color"
                :disabled="toggle.enabled && !toggle.toggled"
                :class="[
                  successButton?.halfWidth ? 'half-width' : '',
                  successButton?.fitContent ? 'fit-content' : '',
                ]"
                :loading="successButtonLoading"
                size="md"
                :text-uppercase="false"
                @click="success"
              >
                {{ successButton.text }}
              </n-button>
            </n-layout>
          </div>
        </div>
      </div>
    </n-theme>
  </modal>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dialog',
    },
    fullScreen: {
      type: Boolean,
      default: false,
      validator: (fullScreen) => typeof fullScreen === 'boolean',
    },
  },
  data: () => {
    return {
      params: {},
      successButtonLoading: false,
      successButton: {},
      cancelButton: {},
      width: window.innerWidth - 76 + 'px',
      contentWidth: 'auto',
      cancel: true,
      explicitClose: false,
      theme: 'passenger',
      toggle: {
        enabled: false,
        toggled: false,
        text: '',
      },
      closeButton: false,
      iconComponent: null,
      iconProps: {},
      customWidth: null,
      textPreset: 'body',
      buttonsInline: false,
      headerPicture: null,
    };
  },
  computed: {
    canClickToClose() {
      return (
        this.params.clickOutside &&
        (this.params.closable === true || this.cancel)
      );
    },
    open() {
      return this?.$refs.modal?.visible ?? false;
    },
  },
  methods: {
    beforeOpened(event) {
      const defaultValues = this.$options.data();

      this.explicitClose = false;
      this.params = event.params || {};

      this.closable = this.params.closable ?? true;
      this.buttonsInline = this.params.buttonsInline ?? defaultValues.buttonsInline;
      this.textPreset = this.params.textPreset ?? defaultValues.textPreset;
      this.closeButton = this.params.closeButton ?? defaultValues.closeButton;
      this.contentWidth = this.params.customWidth ?? defaultValues.contentWidth;
      this.cancelButton = this.params.cancelButton ?? defaultValues.cancelButton;
      this.theme = this.params.theme ?? defaultValues.theme;
      this.iconComponent = this.params.icon?.component ?? defaultValues.iconComponent;
      this.iconProps = this.params.icon?.props ?? defaultValues.iconProps;

      if (this.params.clickOutside === null) {
        this.params.clickOutside = true;
      }

      if (Array.isArray(this.params.title)) {
        this.params.title = this.params.title.map((part) => ({
          text: part.text || '',
          preset: part.preset || 'header',
          color: part.color || null,
        }));
      }

      this.successButton = this.params.successButton || this.params.success;

      if (!this.successButton.text && !this.params.success.hidden) {
        this.successButton.text = this.$t('c.dialog.ok');
      }

      if ('cancel' in this.params) {
        this.cancel = this.params.cancel;
      }

      if (!this.cancelButton.text) {
        this.cancelButton.text = this.$t('c.dialog.cancel');
      }


      if ('toggle' in this.params) {
        this.toggle = this.params.toggle;
      } else {
        this.toggle = {
          enabled: false,
          toggled: false,
        };
      }

      if(this.params.headerPicture){
        this.headerPicture = this.params.headerPicture;
      }
    },
    success() {
      if (this.toggle.enabled && !this.toggle.toggled) {
        return;
      }

      if (this.successButton.handler) {
        const result = this.successButton.handler();

        if (result instanceof Promise) {
          this.successButtonLoading = true;

          result.finally(() => {
            this.close();

            this.successButtonLoading = false;
          });

          return;
        }
      }

      this.close();
    },
    cancelAction() {
      if (this.cancelButton.handler) {
        this.cancelButton.handler();
      }

      this.close();
    },
    close() {
      this.$modal.hide(this.name);
      this.explicitClose = true;
    },
    /**
     * When clicking on the background it supposed to close.
     * Bug it appears there is a bug in the vuejs modal lib.
     * When change tab between trips and recurring it reappears. This is a workaround.
     */
    forceClose() {
      if (this.closable || this.params.closable || this.cancel) {
        setTimeout(() => {
            this.close();
          }, 300);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/style/styleguide.scss';

.dialog {
  z-index: 9999;
  pointer-events: all;

  .container {
    display: flex;
    justify-content: center;
  }

  .v--modal {
    box-shadow: 0 3px 6px var(--color-shadow);
    border-radius: 16px;
  }

  .title {
    text-align: center;
  }

  .icon-container, .header-container {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    margin-top: 50px;
  }

  .content {
    padding: 20px;
    justify-items: center;

    & > .row {
      grid-column-start: 1;
      grid-column-end: span col4-start;
    }

    .message {
      margin: 10px 0;
      text-align: center;
      white-space: break-spaces;
    }

    &.buttons {
      padding-top: 0;
    }
  }

  .button-container {
    justify-content: center;
    display: flex;
    width: 100%;
    
    .button-content {
      display: flex;
      justify-content: center;
      align-self: center;
      width: fit-content;
      padding-bottom: 20px;
      min-width: calc(50% + var(--grid-padding-x) * 2);
      
      &.inline-buttons {
        flex-direction: column-reverse;
      }
      
      .half-width {
        width: 50%;
      }
      .fit-content {
        width: fit-content;
      }
    }
  }
    
  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease;
  }

  .close-button:hover {
    opacity: 0.8;
  }

  .header-picture {
    max-width:75px; 
    max-height:75px;
  }
}
</style>