import throttle from 'lodash.throttle';
import apiCommute from '@/api/commute';
import apiTrip from '@/api/trip';
import { isLocationWithinLifetime } from '@/screens/main/activeTrip/support/location';
import { unprefixKeys } from '@/vendor/utils';

let locationUpdateThrottle = null;
let locationUpdatePromiseResolvers = [];

// initial state
const initialState = () => ({
  activeDriverTrip: null,
  activeDriverTripParking: null,
  driverTrip: null,
  bookingTrip: null,
  location: null,
  locationUpdatedAt: null,
  subsidisable: false,
  distance: null,
  origin: null,
  destination: null,
  code: {
    value: null,
    link: null,
    renews_at: null
  },
  paused: false
});

// getters
const getters = {};

// actions
const actions = {
  createTrip({ dispatch, commit, state }) {
    const data = {
      origin: state.origin,
      destination: state.destination?.transformed
        ? state.destination.parts
        : unprefixKeys(state.destination?.parts, '_')
    };

    return apiTrip.createOnDemandTrip(data)
      .then(({ trip }) => {
        commit('setActiveDriverTrip', trip);
        dispatch('commute/UPDATE_OVERVIEW', null, { root: true });

        return trip;
      });
  },
  getTrip({ dispatch, commit, state }, tripId) {
    return apiTrip.getTrip(tripId)
      .then(({ trip }) => {
        commit('setDriverTrip', trip);
        return trip;
      })
      .catch(() => {
        commit('invalidateDriverTrip');
      });
  },
  refreshActiveTrip({ dispatch, commit, state }) {
    return apiTrip.getActiveTrip()
      .then(({ trip }) => {
        commit('setActiveDriverTrip', trip);
        
        if (trip.parking_site_name) {
          dispatch('refreshActiveTripParking', trip.id);
        }

        return trip;
      })
      .catch(() => {
        commit('invalidateDriverTrip');
      });
  },
  refreshActiveTripParking({ commit, state }, driverTripId) {
    return apiCommute.getParkingSpot(driverTripId)
      .then(( parkingData) => {
        commit('setActiveDriverTripParking', parkingData);
        return parkingData;
      })
      .catch(() => {
        commit('invalidateDriverTripParking');
      });
  },
  async endTrip({ commit, dispatch, state }, {userExpectsSubsidy = false, trip_id}) {
    try {
      await apiTrip.endTrip(userExpectsSubsidy, trip_id);
    } catch (e) {
      if (e?.response?.status === 404) {
        console.log('[trip store] trip already ended');
      } else {
        throw e;
      }
    }

    await dispatch('commute/UPDATE_OVERVIEW', null, { root: true });

    commit('setDriverTrip', null);
    commit('invalidateDriverTrip');
    commit('invalidateDriverTripParking');
    commit('setDistance', null);
    commit('invalidateLocation');
    commit('invalidateCode');

  },
  async flushUpdateLocationThrottle() {
    if (locationUpdateThrottle?.flush instanceof Function) {
      await locationUpdateThrottle.flush();
    }
  },
  /**
   *
   * @param {import('vuex').ActionContext} context
   * @param {UserLocation} location
   * @param {string} updatedAt
   * @returns
   */
  throttledUpdateLocation({ dispatch, commit, state }, location, updatedAt = null) {
    const updatedPromise = new Promise(resolve => {
      locationUpdatePromiseResolvers.push(resolve);
    });

    if (!locationUpdateThrottle) {
      locationUpdateThrottle = throttle(async ({ dispatch }, location, updatedAt = null) => {
        if (updatedAt !== null && !isLocationWithinLifetime(updatedAt)) {
          console.log('[active-trip] discarding outdated location', updatedAt, new Date().toJSON());
          return;
        }

        await dispatch('updateLocation', location, updatedAt);

        while (locationUpdatePromiseResolvers.length > 0) {
          const resolver = locationUpdatePromiseResolvers.shift();

          if (resolver instanceof Function) {
            resolver();
          }
        }
      }, 10 * 1000, { leading: true, trailing: true });
    }

    locationUpdateThrottle({ dispatch, commit, state }, location, updatedAt)

    return updatedPromise;
  },
  /**
   *
   * @param {import('vuex').ActionContext} context
   * @param {UserLocation} location
   * @param {string} updatedAt
   * @returns
   */
  updateLocation({ commit, state }, location, updatedAt = null) {
    const id = state.activeDriverTrip?.id;

    if (!id) {
      return;
    }

    return apiTrip.createTripPosition(id, location)
      .then((response) => {
        commit('setLocation', location, updatedAt);
        commit('setSubsidisable', response?.subsidisable ?? false);
        commit('setDistance', response?.distance);
      }).catch((error) => {
        if (error.handled) {
          return;
        }
        throw error;
      });
  },
  renewCode({ commit, state }) {
    const id = state.activeDriverTrip?.id;
    if (!id) {
      return Promise.reject(new Error('no_active_trip'));
    }

    return apiTrip
      .createTripCode(id)
      .then(code => {
        if (!code?.value || !code?.link || !code?.renews_in) {
          return Promise.reject(new Error('Invalid code'));
        }

        const renewsAt = new Date();

        renewsAt.setSeconds(renewsAt.getSeconds() + code.renews_in);

        commit('setCode', {
          ...code,
          renews_at: renewsAt.toJSON()
        });

        return code;
      })
      .catch(error => {
        commit('invalidateCode');
        return Promise.reject(error);
      });
  },
  searchCode({ commit }, code) {
    return apiTrip.searchTripCode(code)
      .then(({ trip }) => {
        commit('setBookingTrip', trip);
        return trip;
      });
  },
  bookTrip({ commit, state }) {
    const id = state.bookingTrip?.prospect_id;

    if (!id) {
      return Promise.reject(new Error('No trip prospect found'));
    }

    return apiTrip.bookTrip(id)
      .then(({ trip }) => {
        commit('setBookingTrip', trip);
        return trip;
      });
  },
  cancelPassengerTrip({ commit, state }, passengerTripId) {
    return apiCommute.cancelBooking(passengerTripId);
  },
};

// mutations
const mutations = {
  setDriverTrip(state, driverTrip) {
    state.driverTrip = driverTrip;
  },
  setActiveDriverTrip(state, driverTrip) {
    state.activeDriverTrip = driverTrip;
  },
  setActiveDriverTripParking(state, parkingData){
    if(state.activeDriverTripParking === parkingData){
      return;
    }

    state.activeDriverTripParking = parkingData;
  },
  /**
   *
   * @param {*} state
   * @param {UserLocation} location
   * @param {string} updatedAt
   */
  setLocation(state, location, updatedAt = null) {
    state.location = location;
    state.locationUpdatedAt = updatedAt ?? new Date().toJSON();
  },
  setSubsidisable(state, subsidisable) {
    state.subsidisable = subsidisable;
  },
  setDistance(state, distance) {
    state.distance = distance;
  },
  invalidateLocation(state) {
    state.locationUpdatedAt = null;
  },
  /**
   *
   * @param {*} state
   * @param {UserLocation} origin
   */
  setOrigin(state, origin) {
    state.origin = origin;
  },
  /**
   *
   * @param {*} state
   * @param {UserLocation} destination
   */
  setDestination(state, destination) {
    state.destination = destination;
  },
  setCode(state, code) {
    state.code = code;
  },
  invalidateCode(state) {
    state.code = initialState().code;
  },
  setPassengers(state, passengers) {
    state.passengers = passengers;
  },
  pause(state) {
    state.paused = true;
  },
  resume(state) {
    state.paused = false;
  },
  invalidateDriverTrip(state) {
    const { activeDriverTrip, activeDriverTripParking, driverTrip, paused, code } = initialState();

    state.activeDriverTrip = activeDriverTrip;
    state.activeDriverTripParking = activeDriverTripParking;
    state.driverTrip = driverTrip;
    state.paused = paused;
    state.code = code;
  },
  invalidateDriverTripParking(state) {
    state.activeDriverTripParking = initialState().activeDriverTripParking;
  },
  setBookingTrip(state, trip) {
    state.bookingTrip = trip;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};

