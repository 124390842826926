<template>
  <n-grid>
    <n-column :span="6">
      <div class="info-container" :class="{ [`bg-${bgColor}`]: true }">
        <div class="info-content">
          <n-icon
            :name="iconName"
            color="transparent"
            outline
          />
          <div class="text-content">
            <n-text
              class="title"
              size="md"
              weight="medium"
            >
              {{ title }}
            </n-text>
            <n-text
              size="sm"
            >
              {{ text }}
            </n-text>
          </div>
        </div>
      </div>
    </n-column>
  </n-grid>
</template>

<script>
export default {
  name: 'InfoBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      default: 'info-large',
    },
    bgColor: {
      type: String,
      default: 'blue',
      validator: (value) => ['blue', 'grey'].includes(value),
    },
    titleColor: {
      type: String,
      default: 'blue-dark',
    },
    textColor: {
      type: String,
      default: 'blue-neutral',
    },
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  border-radius: 6px;
  padding: 20px;
  margin: 16px 0;

  &.bg-blue {
    background-color: var(--color-blue-50);
    color: var(--color-blue-700);

    .title {
      color: var(--color-blue-800);
    }
  }

  &.bg-grey {
    background-color: var(--color-grey-50);
    color: var(--color-grey-800);

    .title {
      color: var(--color-grey-900);
    }
  }

  .info-content {
    display: flex;
    gap: 18px;
    align-items: center;

    .text-content {
      display: flex;
      flex-direction: column;
    }
  }
}
</style> 