<template>
  <div v-show="isVisible" class="modal-wrapper" :class="{ 'modal-closing': isClosing }">
    <div class="modal-backdrop" @click="handleBackdropClick"></div>
    <div class="modal-container" :style="modalStyle">
      <div class="close-button" @click="close">
        <n-icon name="close-dialog" size="md" color="close-gray" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  
  props: {
    width: {
      type: [String, Number],
      default: () => window.innerWidth - 76 + 'px'
    },
    height: {
      type: String,
      default: 'auto'
    },
    clickToClose: {
      type: Boolean,
      default: true
    }
  },
  
  data() {
    return {
      isVisible: false,
      isClosing: false
    };
  },
  
  computed: {
    modalStyle() {
      return {
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        height: this.height
      };
    }
  },
  
  methods: {
    show() {
      this.isClosing = false;
      this.isVisible = true;
      document.body.style.overflow = 'hidden'; // Prevent body scroll
    },
    
    close() {
      this.isClosing = true;
      
      // Wait for animation to complete before hiding
      setTimeout(() => {
        this.isVisible = false;
        this.isClosing = false;
        document.body.style.overflow = ''; // Restore body scroll
        this.$emit('close');
      }, 300); // Match this with the CSS animation duration
    },
    
    handleBackdropClick() {
      if (this.clickToClose) {
        this.close();
      }
    }
  },
  
  beforeDestroy() {
    // Ensure body scroll is restored when component is destroyed
    document.body.style.overflow = '';
  }
};
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
}

.modal-backdrop {
  pointer-events: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000;
  transition: opacity 0.4s ease-in-out;
}

.modal-container {
  position: relative;
  background: white;
  border-radius: 16px;
  max-height: 90vh;
  overflow: auto;
  z-index: 1001;
  margin: 20px;
  pointer-events: auto;
  box-shadow: 0 3px 6px var(--color-shadow);
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease;
}

.close-button:hover {
  opacity: 0.8;
}

/* Add smooth transitions */
.modal-wrapper,
.modal-container {
  animation: fadeIn 0.2s ease-out;
}

.modal-closing .modal-backdrop,
.modal-closing .modal-container {
  animation: fadeOut 0.25s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}
</style> 