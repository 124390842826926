/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_chevron': {
    width: 24,
    height: 14,
    viewBox: '0 0 24 14',
    fill: 'none',
    data: '<path d="M2 12L11.97 2L22 12" stroke="currentColor" stroke-width="3.5" stroke-linecap="round"/>'
  }
})