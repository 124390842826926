<template>
  <span class="selector-container">
    <n-text 
      v-if="withLabel"
      size="sm"
      color="grey-bold"
    >
      {{ $t('main.provide.dropdownCharge.header') }}
      <n-help icon="info" :text="helpText" />
    </n-text>
    <icon-dropdown
      v-model="selectedOptionObject"
      :options="options"
      class="charge-dropdown"
      @input="handleChange"
    />
  </span>
</template>

<script>
import i18n from '@/i18n';
import { mapState } from 'vuex';
import { namespace as userNamespace } from '@/store/modules/user-types';
import IconDropdown from '@/components/core/inputs/iconDropdown.vue';

export default {
  components: { IconDropdown },
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      selectedOption: this.value ? 'charge' : 'onlySubsidy',
      selectedOptionObject: null,
    };
  },
  computed: {
    ...mapState(userNamespace, ['profile']),
    helpText() {
      return i18n.t('main.provide.dropdownCharge.helpModal');
    },
    options() {
      return [
        {
          id: 'charge',
          text: i18n.t('main.provide.dropdownCharge.positive'),
          icon: ''
        },
        {
          id: 'onlySubsidy',
          text: i18n.t('main.provide.dropdownCharge.negative'),
          icon: ''
        }
      ];
    }
  },
  methods: {
    handleChange(event) {
      if (event) {
        this.selectedOptionObject = event;
        this.selectedOption = event.id;
        this.$emit('input', this.selectedOption === 'charge');
      }
    },
    getOption() {
      return this.options.find(option => option.id === this.selectedOption);
    },
    initializeSelectedOption() {
      const option = this.options.find(opt => opt.id === this.selectedOption);
      if (option) {
        this.selectedOptionObject = option;
      }
    }
  },
  watch: {
    value(newValue) {
      this.selectedOption = newValue ? 'charge' : 'onlySubsidy';
      this.initializeSelectedOption();
    }
  },
  created() {
    this.initializeSelectedOption();
  }
};
</script>

<style lang="scss" scoped>
.selector-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}
.charge-dropdown {
  width: 100%;
}
</style>
