<template>
  <div class="login-container">
    <div class="form-container">
      <div class="input-container">
        <phone-number-input @valueChanged="updatePhoneNumber($event)" />
        <terms
          id="terms"
          v-model="termsAccepted"
        />
      </div>
      <recaptcha-button
        ref="recaptcha"
        size="lg"
        block
        :disabled="!termsAccepted || !phoneCountryCode || !phoneNumber"
        :loading="checkingPhone"
        @click="phoneContinue"
      >
        {{ $t('main.start.login.continue') }}
      </recaptcha-button>
    </div>
    <recaptcha-terms />
  </div>
</template>

<script>
import i18n from '@/i18n';
import store from '@/store';
import userApi from '@/api/user';
import Terms from '@/components/shared/terms';
import RecaptchaTerms from '@/components/shared/recaptchaTerms';
import RecaptchaButton from '@/components/shared/recaptchaButton';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import { phoneCountryOptions } from '@/vendor/static-options';
import PhoneNumberInput from '@/components/core/inputs/phoneNumberInput.vue';

export default {
  name: 'authPhoneAuth',
  components: {
    PhoneNumberInput,
    Terms,
    RecaptchaTerms,
    RecaptchaButton
  },
  data() {
    return {
      recaptcha: null,
      phoneCountryOptions: phoneCountryOptions,
      checkingPhone: false,
      answeringCaptcha: false,
      termsAccepted: false,
      termsNotAcceptedAnimation: false,
      selectedPhoneCountry: null,
      phoneCountryCode: null,
      phoneNumber: null
    };
  },
  methods: {
    updatePhoneNumber(newPhoneNumber) {
      this.phoneCountryCode = newPhoneNumber.phoneCountryCode;
      this.phoneNumber = newPhoneNumber.phoneNumber;
    },
    async phoneContinue(recaptcha) {
      if (this.checkingPhone) {
        return;
      }

      const phoneCountryCode = this.phoneCountryCode;
      const phoneSubscriberNumber = this.phoneNumber;

      const phone = `${phoneCountryCode}${phoneSubscriberNumber}`;

      this.checkingPhone = true;
      let exists = false;

      try {
        exists = await userApi.checkPhone(phone);
      } catch (e) {
        this.$error({ description: i18n.t(e?.response?.data?.message) });
        this.checkingPhone = false;
        return;
      }

      const storeType = exists
        ? userTypes.REQUEST_PHONE_AUTH_OTP
        : userTypes.REQUEST_PHONE_OTP;

      try {
        await store.dispatch(storeType, { phone, recaptcha });
      } catch (e) {
        this.handleOTPError(e);
        return;
      } finally {
        this.checkingPhone = false;
        this.$refs.recaptcha.reset();
      }

      this.$emit('otp-requested', {
        phoneNumber: this.phoneNumber,
        phoneCountryCode: this.phoneCountryCode,
        exists
      });
    },
    handleOTPError(error) {
      if (error?.response?.status == 429) {
        this.$emit('otp-error', i18n.t('error.auth.otp.throttle_error'));
      } else if (error?.response?.status == 403) {
        this.$emit('otp-error', i18n.t('error.blocked.phone'));
      } else if (error?.response?.status == 403) {
        this.$emit('otp-error', i18n.t('error.blocked.phone'));
      } else {
        this.$emit('otp-error');
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 0.625rem;
}
.input-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

#terms {
  animation: none;
}
</style>
