<template>
  <div class="view-container">
    <n-nav-bar
      v-if="participant.is_deleted"
      :title="$t('chat.deleted-user.name')"
    />
    <n-nav-bar
      v-else
      :title="participant.name ?? 'loading'"
    >
      <template slot="title">
        <div
          v-if="!loading"
          class="top-container"
        >
          <div class="profile-picture">
            <profile-picture
              :src="participant.image"
              @click="openProfile"
            />
          </div>
          <div class="participant-container">
            <div class="participant-name">
              {{ participant.name }}
            </div>
            <rating :stars="participant.ratings_average" />
          </div>
        </div>
      </template>
    </n-nav-bar>

    <n-full-screen v-if="loading">
      <n-spinner class="span-6 flex-center" />
    </n-full-screen>
    <n-scrollable
      v-else
      ref="scrollable"
    >
      <n-layout>
        <div
          v-for="message in messages"
          :key="message.id"
          class="message span-6"
          :class="{ me: isSenderCurrentUser(message) }"
        >
          <n-text
            class="time"
            preset="label-3"
            color="grey-dark"
          >
            {{ dateFormat(message.created_at) }}
          </n-text>
          <n-text class="text">
            {{ message.content }}
          </n-text>
        </div>
      </n-layout>
    </n-scrollable>
    <div
      class="bottom-container shadow-reverse"
      :spacing-y="false"
      :spacing-x="false"
    >
      <div class="textarea-container">
        <n-textarea
          v-model="messageContent"
          class="textbox"
          label=""
          :rows="1"
          :disabled="participant.is_deleted"
          :placeholder="textareaPlaceholder"
          :max-height="80"
        />
      </div>
      <div
        class="feedback send-button"
        @click="send"
      >
        <n-button
          icon="send"

          inverted
          :loading="sending"
          icon-size="lg"
          no-padding
        />
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import { EventBus } from '@/vendor/events';
import userApi from '@/api/user';
import Rating from '@/components/shared/rating';
import ProfilePicture from '@/components/shared/profilePicture';
import { formatRelative } from '@/vendor/date-fns';
import { mapState } from 'vuex';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  components: { ProfilePicture, Rating },
  props: {
    userId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      messageContent: '',
      participant: {},
      sending: false,
      loading: true,

      intervalId: null,
      abortController: null,
    };
  },
  computed: {
    ...mapState('user', {
      currentUser: 'profile',
    }),
    sendButtonColor() {
      return this.participant.is_deleted
        ? 'grey'
        : 'accent';
    },
    textareaPlaceholder() {
      return this.participant.is_deleted
        ? this.$t('chat.text-placeholder.disabled')
        : this.$t('chat.text-placeholder.active');
    }
  },
  created() {
    EventBus.$on('notification', this.fetchMessageOnNewNotification);
    this.startFetchingMessagesContinuously();
  },
  beforeDestroy() {
    EventBus.$off('notification', this.fetchMessageOnNewNotification);
    this.stopFetchingMessagesContinuously();
  },
  methods: {
    fetchMessageOnNewNotification(obj) {
      if (obj.route === 'userConversation') {
        this.fetchMessages();
      }
    },
    startFetchingMessagesContinuously() {
      this.fetchMessages();
      const fiveSeconds = 5000;
      this.intervalId = setInterval(this.fetchMessages, fiveSeconds);
    },
    stopFetchingMessagesContinuously() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      if (this.abortController) {
        this.abortController.abort();
      }
    },

    async fetchMessages() {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();

      try {
        const { messages, participant } = await userApi.getUserConversation(this.userId, { signal: this.abortController.signal });

        this.messages = messages;
        this.participant = participant;
      } catch (error) {
        if (error.code !== 'ERR_CANCELED') {
          console.error('Failed to fetch messages:', error);
        }
      } finally {
        this.loading = false;
        this.markConversationAsRead(this.participant.id);
        this.scrollToBottom();
      }
    },

    async send() {
      if (
        this.participant.is_deleted ||
        this.messageContent.trim() === '' ||
        this.sending
      ) {
        return;
      }

      this.sending = true;
      try {
        const message = await userApi.newConversationMessage(
          this.messageContent,
          this.userId
        );
        this.messages.push(message);
        this.messageContent = '';
      } catch (error) {
        this.$error();
      } finally {
        this.scrollToBottom();
        this.sending = false;
      }
    },

    dateFormat(date) {
      return formatRelative(new Date(date), new Date());
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (!this.$refs.scrollable) {
          return;
        }

        const el = this.$refs.scrollable.$el;
        el.scrollTop = el.scrollHeight;
      });
    },
    markConversationAsRead(participantId) {
      this.$store.dispatch(userTypes.READ_NOTIFICATIONS, {
        type: 'userConversation',
        reference: participantId,
      });
    },
    openProfile() {
      EventBus.$emit('open-profile', this.participant.id);
    },
    isSenderCurrentUser(message) {
      return message.sender_id === this.currentUser.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  flex-direction: column;

  &.me {
    align-items: flex-end;

    .text {
      background: var(--color-accent);
      color: var(--color-white);
    }
  }

  .time {
    width: 80%;
    text-align: center;
  }

  .text {
    background: var(--color-grey-light);
    padding: 0.85em 1.2rem;
    border-radius: 34px;
    width: 80%;
  }
}

.profile-seperator {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.top-container {
  display:flex;
  flex-direction: row;
  .profile-picture {
    margin-right:10px;
    flex-shrink: 1;
  }
  
  .participant-container {
   flex-grow:1;
    text-align:left;
  }
}


.bottom-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-top: 1px solid var(--color-grey-light);
  
  .textarea-container {
    flex: 1;
  }
}

.textbox {
  margin: 0.75rem 0 0.75rem 1rem;
}

.send-button {
  margin: 0.75rem;
  display: flex;

  .icon-wrapper {
    margin-bottom: 4px;
  }

  .button-container {
    width:50px;
  }

}
</style>
