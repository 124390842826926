<template>
  <div class="container">
    <div class="inner">
      <svg-icon
        :icon="`ng-logo-nabogo`"
        style="height: 55px; width: 300px; margin-bottom: 3rem"
      ></svg-icon>
      <slot />
      <div class="store-links">
        <store-link store="google" />
        <store-link store="apple" />
      </div>
    </div>
  </div>
</template>

<script>
import { isBrowser } from '@/device';
import StoreLink from '@/components/shared/links/storeLink';

export default {
  components: {
    StoreLink,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.checkBrowser();
    });
  },
  mounted() {
    window.addEventListener('resize', this.delayedCheckBrowser);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.delayedCheckBrowser);
  },
  methods: {
    delayedCheckBrowser() {
        setTimeout(() => {
            this?.checkBrowser();
        }, 200);
    },
    checkBrowser() {
      if (!isBrowser()) {
        this.$router.push('/');
      }
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  height: 100%;
}
.inner {
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
}
.store-links {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-evenly;
  width: 100%;
}
</style>
