<template>
  <span class="selector-container">
    <n-text 
      v-if="withLabel"
      size="sm"
      color="grey-bold" 
    >
      {{ $t('main.searchHeader.seats') }}
    </n-text>

    <icon-dropdown
      v-model="selectedSeatObject"
      icon="person"
      :options="seatOptions"
      class="country-code-input"
      @input="onSeatChange"
    />
  </span>
</template>
<script>
import IconDropdown from '@/components/core/inputs/iconDropdown.vue';
export default {
  components: { IconDropdown },
  props: {
    value: {
      type: Number,
      required: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 4,
    },
  },
  data: function() {
    return {
      selectedSeats: this.value || this.min,
      selectedSeatObject: null,
    };
  },
  computed: {
    seatOptions() {
      const offSet = this.min - 1;
      return Array(this.max - offSet)
        .fill(null)
        .map((_, i) => {
          return {
            id: String(i + 1 + offSet),
            text: `${this.$tc('c.searchHeader.seats', i + 1 + offSet)}`,
            flag: this.withIcon ? '👤' : '',
          };
        });
    }
  },
  methods: {
    onSeatChange(seatObject) {
      if (seatObject) {
        this.selectedSeatObject = seatObject;
        this.selectedSeats = parseInt(seatObject.id, 10);
        this.$emit('input', this.selectedSeats);
      }
    },
    getSelectedSeats() {
      return this.selectedSeats;
    },
    initializeSelectedSeatObject() {
      const offSet = this.min - 1;
      const initialValue = this.value || this.min;
      const seatIndex = initialValue - this.min;
      
      if (seatIndex >= 0 && seatIndex < this.seatOptions.length) {
        this.selectedSeatObject = this.seatOptions[seatIndex];
      } else {
        this.selectedSeatObject = this.seatOptions[0];
      }
      
      this.selectedSeats = parseInt(this.selectedSeatObject.id, 10);
      this.$emit('input', this.selectedSeats);
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.selectedSeats = newValue;
        const matchingOption = this.seatOptions.find(
          (option) => parseInt(option.id, 10) === newValue
        );
        if (matchingOption) {
          this.selectedSeatObject = matchingOption;
        }
      }
    }
  },
  created() {
    this.initializeSelectedSeatObject();
  }
};
</script>

<style lang="scss" scoped>
.selector-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
}
</style>
