<template>
  <n-bottom-sheet
    type="header"
    :no-layout="iframeUrl && !iframeLoading"
    :title="$t('paymentSheet.title')"
    class="payment-sheet"
    ref="sheet"
    :fill-height="iframeUrl != false && iframeUrl != null"
    @dismissed="onPaymentSheetDismissed"
    :pad-bottom-extra="true"
  >
    <iframe
      v-if="iframeUrl"
      v-show="!iframeLoading"
      :src="iframeUrl"
      @load="iframeLoaded"
      class="span-6"
    />
    <n-grid v-if="!iframeUrl || iframeLoading" :row-gap="4">
      <template v-if="trip">
        <n-text class="span-6">{{ $t('paymentSheet.tripPayment') }}</n-text>
        <n-text preset="sub" class="span-6"
          >{{ $t('paymentSheet.tripPrice') }}:
          {{ $n(price, 'currency', profile.currency) }}</n-text
        >
        <n-text preset="sub" class="span-6"
          >{{ $t('paymentSheet.balance') }}:
          {{
            $n(profile.balance_available, 'currency', profile.currency)
          }}</n-text
        >
      </template>
      <n-grid>
        <n-text class="span-6" color="accent" preset="header">{{
          $t('paymentSheet.method')
        }}</n-text>
        <n-radio
          v-for="paymentMethod in paymentMethods"
          :key="paymentMethod.id"
          name="method"
          :value="paymentMethod.id"
          v-model="selectedPaymentMethod"
          class="span-3"
        >
          <n-text color="accent" preset="label-2">{{ paymentMethod.name }}</n-text>
        </n-radio>
      </n-grid>
      <n-seperator class="span-6" />
      <n-grid :bottom-gap="3">
        <n-text class="span-6" color="accent" preset="header">{{
          $t('paymentSheet.chooseAmount')
        }}</n-text>
        <n-radio
          v-for="amountOption in amountOptions"
          :key="amountOption"
          name="amount"
          :value="amountOption"
          v-model="selectedPaymentAmount"
          class="span-3"
        >
          <n-text color="accent" preset="label-2">{{
            $n(amountOption, 'currency', profile.currency)
          }}</n-text>
        </n-radio>
      </n-grid>
      <n-spinner v-if="loading || iframeLoading" class="span-6 flex-center" />
      <n-button size="lg" :disabled="!canProceed" block @click="proceed">{{
        $t('paymentSheet.button')
      }}</n-button>
    </n-grid>
  </n-bottom-sheet>
</template>

<script>
import store from '@/store';
import userApi from '@/api/user';
import { EventBus } from '@/vendor/events';
import { mapState } from 'vuex';
import {
  namespace as userNamespace,
  namespacedTypes as userTypes,
} from '@/store/modules/user-types';
import { currencyPaymentOptions, currencyPaymentAmounts } from '@/vendor/static-options';
import { isExpoApp } from '@/device';

export default {
  data: () => {
    return {
      price: 0,
      missingAmount: 0,
      trip: null,
      selectedPaymentAmount: null,
      selectedPaymentMethod: null,
      loading: false,
      tripId: null,
      discountReason: null,
      iframeUrl: null,
      iframeLoading: false,
    };
  },
  computed: {
    ...mapState(userNamespace, ['profile']),
    paymentMethods() {

      const paymentTypes= [
        ...(this.profile.currency ? currencyPaymentOptions[this.profile.currency] : []),
        { id: 'apple-pay', name: 'Apple Pay' },
        { id: 'creditcard', name: this.$t('paymentSheet.card') }
      ];
      if (this.isTravelPassEnabled && this.tripId) {
        paymentTypes.push({ id: 'commuter', name: this.$t('paymentSheet.commuter') });
      }
      return paymentTypes;
    },
    canProceed() {
      return (
        this.selectedPaymentAmount > 0 && this.selectedPaymentMethod !== null
      );
    },
    amountOptions() {
      let amountOptions = currencyPaymentAmounts[this.profile.currency];
      const isValidMissingAmount = !isNaN(this.missingAmount) && this.missingAmount > 0;

      if (!isValidMissingAmount) {
        return amountOptions;
      }

      // This is a workaround for a bug which sometimes casues amountOptions to be null or undefined
      if (!amountOptions) {
        console.warn(`No payment amounts for currency ${this.profile.currency}`);
        amountOptions = [...currencyPaymentAmounts['DKK'], ...currencyPaymentAmounts['EUR']] ;
      }

      const amountsAboveMissingAmount = [this.missingAmount, ...amountOptions]
        .filter((amount) => this.missingAmount <= amount)

      return [...new Set(amountsAboveMissingAmount)].sort();
    },
    isTravelPassEnabled() {
      return this.$store.getters[userTypes.HAS_FEATURE_FLAG]('CommuterPass');
    },
  },
  mounted() {
    EventBus.$on('open-payment-sheet', this.onPaymentSheetOpen);
    EventBus.$on('router-event-payment-success', this.onPaymentSuccess);
    EventBus.$on('router-event-payment-cancelled', this.onPaymentCancel);

    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window[eventMethod](messageEvent, this.onChildWindowMessage);
  },
  beforeDestroy() {
    EventBus.$off('open-payment-sheet', this.onPaymentSheetOpen);
    EventBus.$off('router-event-payment-success', this.onPaymentSuccess);
    EventBus.$off('router-event-payment-cancelled', this.onPaymentCancel);

    const eventMethod = window.addEventListener
      ? 'removeEventListener'
      : 'detachEvent';
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window[eventMethod](messageEvent, this.onChildWindowMessage);
  },
  methods: {
    onPaymentSheetOpen(price, tripId, discountReason) {
      this.tripId = null;
      this.missingAmount = null;
      this.discountReason = null;

      this.$refs.sheet.open();

      if (price) {
        // If opened with inefficient balance, it's possible to give the price to make a custom refill option for the exact missing amount
        this.price = price;
        this.tripId = tripId; // If filling up when booking a trip
        this.missingAmount = this.price - this.profile.balance_available;
        this.discountReason = discountReason;
        this.selectedPaymentAmount = this.missingAmount;
      }

      this.preselectOptions();
    },
    preselectOptions() {
      this.selectedPaymentMethod = this.paymentMethods[0].id;
      this.selectedPaymentAmount = this.amountOptions[0];
    },
    onPaymentSheetDismissed() {
      this.selectedPaymentMethod = null;
      this.selectedPaymentAmount = null;
      this.iframeUrl = null;
    },
    dismiss() {
      this.$refs.sheet.dismiss();

      this.$nextTick(() => {
        this.onPaymentSheetDismissed();
      });
    },
    onPaymentSuccess() {
      store.dispatch(userTypes.FETCH_PROFILE).then(() => {
        this.$success(this.$t('paymentSheet.success'));
        EventBus.$emit('payment-success-balance-fetched');
      });

      if (!this.$refs.sheet.visible) {
        return;
      }

      this.dismiss();
    },
    onPaymentCancel() {
      if (!this.$refs.sheet.visible) {
        return;
      }

      this.dismiss();
      this.$error(this.$t('paymentSheet.cancelled'));
    },
    onChildWindowMessage(e) {
      if (
        (this.iframeUrl && typeof e.data !== 'object') ||
        e.data.type !== 'payment_callback'
      ) {
        return;
      }

      // The payment has been authorized
      if (e.data.status === 'authorized' || e.data.status === 'captured') {
        this.onPaymentSuccess();
      }

      // The payment has been cancelled
      if (e.data.status === 'cancelled') {
        this.onPaymentCancel();
      }
    },
    proceed() {
      if (this.loading || !this.canProceed) {
        return;
      }

      if (this.selectedPaymentMethod === 'commuter') {
        this.$router.push({ name: 'travel-pass'});
        this.$refs.sheet.dismiss();
        return;
      }

      this.loading = true;

      userApi
        .generateDepositLink(
          this.selectedPaymentMethod,
          this.selectedPaymentAmount,
          this.tripId
        )
        .then((response) => {
          this.dismiss();
          window.sendNative.openURL(response.link);
        })
        .catch((error) => {
          this.$error({
            title: this.$t('serverErrorTitle'),
            description: this.$t('serverErrorDescription'),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    iframeLoaded() {
      this.iframeLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-sheet {
  z-index: 13;
}

iframe {
  width: 100%;
  height: 100%;
  height: calc(100% - 10px);
  border: none;
  overflow: hidden;
}
</style>
