<template>
  <div
    :class="stateClasses"
    @click="updateValue"
  >
    <span>
      <input
        type="radio"
        :name="name"
        :value="value"
        :checked="modelValue === value"
        @change="updateValue"
      >
    </span>
    <span><slot /></span>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    stateClasses() {
      return this.modelValue === this.value
        ? 'input-radio input-radio__selected'
        : 'input-radio';
    }
  },
  methods: {
    updateValue() {
      this.$emit('update:modelValue', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-radio {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid gray;
  border-radius: 10px;

  span {
    display: flex;
    align-items: center;
  }
}

input[type="radio"] {
  appearance: none;
  margin: 0 12px; // Removed top/bottom margin
  border: 2px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;

  &:checked {
    border-color: var(--color-green);
    background: white;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--color-green);
    }
  }
}

.input-radio {
  width: calc(100% - 6px);
  height: 50px;
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-inline: 3px;
  background-color: #FBFBFB;
}

.input-radio__selected {
  @extend .input-radio;
  border: 1px solid var(--color-green);
  box-shadow: 0 0 0 3px var(--color-green-light);
  background-color: white;
}
</style>
