<template>
  <div>
    <div class="trip-options">
      <n-grid class="destination-container">
        <n-text
          preset="label-2"
          class="flex-v-center"
          color="grey-dark"
        >
          {{ $t('c.tripOptions.from') }}
        </n-text>
        <n-text
          class="span-5"
          color="grey-dark"
        >
          {{ !isReturnTrip ? from : to }}
        </n-text>
        <n-text
          preset="label-2"
          class="flex-v-center"
          color="grey-dark"
        >
          {{ $t('c.tripOptions.to') }}
        </n-text>
        <n-text
          class="span-5"
          color="grey-dark"
        >
          {{ !isReturnTrip ? to : from }}
        </n-text>
      </n-grid>
      <n-grid
        v-if="!isDriver"
        :y-gap="5"
      >
        <n-text
          class="span-6"
          size="md"
          color="grey-dark"
          weight="medium"
        >
          {{ $t('c.tripOptions.searchRidesByTitle') }}
        </n-text>
        <n-column class="span-6 flex-start">
          <n-radio
            id="searchBy"
            v-model="searchBy"
            name="searchBy"
            value="departure"
            class="radio-button"
          >
            <n-text
              preset="sub"
              color="grey"
              :weight="isModeSelected('departure') ? 'bold' : 'regular'"
            >
              {{ $t('c.tripOptions.departure') }}
            </n-text>
          </n-radio>
          <n-radio
            id="searchBy"
            v-model="searchBy"
            name="searchBy"
            value="arrival"
            class="radio-button"
          >
            <n-text
              preset="sub"
              color="grey"
              :weight="isModeSelected('arrival') ? 'bold' : 'regular'"
            >
              {{ $t('c.tripOptions.arrival') }}
            </n-text>
          </n-radio>
        </n-column>
      </n-grid>
      <n-grid
        v-if="!isDriver"
        :y-gap="5"
        class="select-time-container span-6"
      >
        <n-text
          size="md"
          weight="medium"
        >
          {{ $t('c.tripOptions.selectTimes') }}
        </n-text>

        <div
          class="information"
          @click="openInformationModal"
        >
          <n-icon
            name="search-info"
            size="md"
          />
        </div>
      </n-grid>
      <n-grid
        :bottom-gap="7"
        :y-gap="5"
        :row-gap="3"
      >
        <n-column
          v-if="isDriver"
          :span="6"
        >
          <seat-selector
            v-model="selectedSeats"
            :min="minSeats"
          />
        </n-column>
        <n-column
          v-if="isDriver"
          :span="6"
        >
          <charge-for-rides-selector v-model="chargeForTrip" />
        </n-column>

        <div
          class="span-6 text-right"
          @click="showTimePickerForDay('all')"
        >
          <n-text
            preset="sub"
            class="text-right set-same-time weight-bold"
            :class="{
              'color-green-700': theme === 'passenger',
              'color-blue-700': theme === 'driver',
            }"
          >
            {{ $t('c.tripOptions.setSameTime') }}
          </n-text>
        </div>
        <template v-for="(day, i) in selectedTimes">
          <n-theme
            :key="i"
            :type="day.checked ? theme : 'deactivated'"
            class="day-container span-6"
          >
            <n-grid
              class="day-box"
              :class="day.checked ? 'activated' : ''"
            >
              <div class="day-checkbox">
                <div
                  class="checkbox"
                  :class="{
                    'checkbox-activated': day.checked,
                  }"
                  @click="checkDay(day)"
                >
                  <n-icon
                    v-if="day.checked"
                    name="checkmark"
                    size="md"
                    color="white"
                    class="checkbox-checkmark"
                  />
                </div>
              </div>
              <label
                :for="i"
                class="day-name"
                @click="checkDay(day)"
              >
                <n-text
                  preset="sub"
                  :color="day.checked ? 'green-dark' : 'grey'"
                >
                  {{ $t('c.tripOptions.every') }} {{ day.text }}
                </n-text>
              </label>
              <div class="day-spacer" />
              <div
                class="time-box"
                @click="showTimePickerForDay(day)"
              >
                <n-text
                  preset="sub"
                  :color="day.checked ? 'green-dark' : 'grey'"
                >
                  {{ formatTime(day.date) }}
                </n-text>

                <n-icon
                  name="chevron-down"
                  outline
                  size="sm"
                  :color="day.checked ? 'green-dark' : 'grey'"
                />
              </div>
            </n-grid>
          </n-theme>
        </template>
      </n-grid>
    </div>

    <template v-if="showTimePicker">
      <n-datetime-picker
        :title="$t('c.searchHeader.timePicker.title')"
        :display-start-time-options="false"
        :description="timePickerDescription"
        :time-mode="by"
        :time-selected="currentSelectedTime"
        :only-time="true"
        :is-recurring="true"
        @datetimeChange="timePickerChanged"
        @dateTimePickerClosed="toggleTimePicker"
      />
    </template>
  </div>
</template>
<script>
import SeatSelector from '@/components/shared/seatSelector';
import ChargeForRidesSelector from '@/components/shared/chargeForRidesSelector.vue';
import DatetimePicker from '@/components/core/overlays/datetimePicker.vue';
import { format } from '@/vendor/date-fns';
import { addHours } from 'date-fns';
import Icon from '../core/typography/icon.vue';

export default {
  components: {
    SeatSelector,
    ChargeForRidesSelector
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    defaultTime: {
      type: Date,
      default: () => new Date(new Date().setHours(9, 0)), // 2 x new, since setHours return since epoch, the wrap gives date object
    },
    by: {
      type: String,
      default: 'departure',
    },
    theme: {
      type: String,
      default: 'driver',
    },
    isDriver: {
      type: Boolean,
      default: false,
    },
    minSeats: {
      type: Number,
      required: false,
      default: 1,
    },
    isReturnTrip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchBy: this.by.toLowerCase(),
      weekdayNames: [
        this.$t('days.monday'),
        this.$t('days.tuesday'),
        this.$t('days.wednesday'),
        this.$t('days.thursday'),
        this.$t('days.friday'),
        this.$t('days.saturday'),
        this.$t('days.sunday'),
      ],
      weekdayIds: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
      selectedTimes: [],
      isEdited: false,
      selectedSeats: this.value.seats,
      chargeForTrip: this.value.chargeForTrip,
      showTimePicker: false,
      currentSelectedTime: null,
      timePickerDescription: this.$t('c.tripOptions.selectForAllDays'),
      timePickerSelectedDay: null,
    };
  },
  watch: {
    selectedTimes: {
      deep: true,
      handler() {
        this.updated();
      },
    },
    searchBy: {
      handler() {
        this.updated();
      },
    },
    selectedSeats() {
      this.updated();
    },
    chargeForTrip() {
      this.updated();
    },
    isReturnTrip: {
      handler() {
        this.searchBy = 'departure';

        this.selectedTimes = this.selectedTimes.map((day) => {
          return {
            ...day,
            date: addHours(day.date, 8),
          };
        });
      },
    },
  },
  mounted() {
    let times = null;
    if (this.value) {
      times = this.value.times?.[0];
    }

    if (this.selectedTimes.length === 0 && this.defaultTime) {
      this.selectedTimes = Array(7)
        .fill(null)
        .map((_, i) => {
          const existingTime = times?.[this.weekdayIds[i]]?.split(':');
          let date = this.defaultTime;

          if (existingTime) {
            date = new Date();
            date.setHours(existingTime[0], existingTime[1]);
          }

          return {
            id: this.weekdayIds[i],
            text: this.weekdayNames[i],
            checked: !times ? i <= 4 : !!existingTime,
            date,
          };
        });
    }

    this.currentSelectedTime = this.defaultTime;

    // The above code triggers the below onTimeChanged which sets the isEdited to true
    // This just sets it back to keep track if the user manually changed something
    this.$nextTick(() => {
      this.isEdited = false;
    });
  },
  methods: {
    formatTime(date) {
      return format(date, 'HH:mm');
    },
    updated() {
      this.$emit('input', {
        times: this.selectedTimes.filter((selectedTime) => selectedTime.checked),
        searchBy: this.searchBy,
        seats: this.selectedSeats,
        chargeForTrip: this.chargeForTrip,
      });
    },
    isModeSelected(mode) {
      return this.searchBy === mode;
    },
    openInformationModal() {
      this.$modal.show('dialog', {
        icon: {
          component: Icon,
          props: {
            name: 'car-right-shield',
            color: 'white',
            size: 'xxl',
          },
        },
        title: [
          {
            text: this.$t('rideAlertInfo.titleStart'),
            color: '#093527',
          },
          {
            text: this.$t('rideAlertInfo.titleEnd'),
            color: '#1A9F74',
          },
        ],
        text: this.$t('rideAlertInfo.description'),
        textPreset: 'sub',
        success: {
          hidden: true,
        },
        color: 'success',
        cancel: false,
        closeButton: true,
      });
    },

    timePickerChanged(time) {
      this.selectedTimes.forEach((day) => {
        if (
          this.timePickerSelectedDay === 'all' ||
          day.id === this.timePickerSelectedDay.id
        ) {
          day.date = time.dateTime ?? time;
        }
      });
    },
    toggleTimePicker() {
      this.showTimePicker = !this.showTimePicker;
    },
    checkDay(day){
      day.checked = !day.checked;
    },
    showTimePickerForDay(day) {
      this.currentSelectedTime = day === 'all' ? this.defaultTime : day.date;
      this.timePickerSelectedDay = day;

      if (day === 'all') {
        this.timePickerDescription = this.$t('c.tripOptions.selectForAllDays');
      } else {
        this.timePickerDescription = `${this.$t('c.tripOptions.every')} ${day.text}`;
      }

      if(day !== 'all' && !day.checked){
        day.checked = !day.checked
      }

      this.toggleTimePicker();
    },
  },
};
</script>
<style lang="scss" scoped>
.destination-container {
  margin: 0 10px;
}

.radio-button {
  margin-right: 10px;
}

.day-container {
  display: flex;
  flex-direction: row;
  color: var(--color-neutral-600);

  .day-box {
    height: 50px;
    color: var(--color-neutral-600);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-neutral-500);
    border-radius: 10px;

    &.activated {
    border: 1px solid var(--color-green-500);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 204, 110, 0.5);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 204, 110, 0.5);
    box-shadow: 0px 0px 3px 0px rgba(0, 204, 110, 0.5);
    color: var(--color-green-900);
  }

    .day-checkbox {
      flex-shrink: 1;
      margin: 0 10px;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      .checkbox {
        display: flex;
      align-items: center;
      justify-content: center;

        width: 26px;
        height: 26px;
        background-color: var(--color-neutral-200);
        border: 0;
        border-radius: 4px;

        &.checkbox-activated {
        background-color: var(--color-green-500);
        }
        .checkbox-checkmark {
          width:18px;
          height:15px;
        }
      }
    }

    .day-name {
      flex-grow: 1;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .day-spacer {
      flex-shrink:1;
      height:33px;
      width:1px;
      border-right: 1px solid var(--color-neutral-500);
    }

    .time-box {
      flex-shrink: 1;
      padding: 0 35px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
    }

    .activated {
      color: var(--color-green-900);
      border-color: var(--color-green-500);
      -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 204, 110, 0.5);
      -moz-box-shadow: 0px 0px 10px 5px rgba(0, 204, 110, 0.5);
      box-shadow: 0px 0px 10px 5px rgba(0, 204, 110, 0.5);
    }
  }
}

.set-same-time {
  padding-right: 20px;
}
.trip-options {
  .select-time-container {
    display: flex;
    width: 100%;

    .information {
      // background: var(--color-neutral-100);
      // color: var(--color-neutral-800);
      width: 20px;
      height: 21px;
      // border-radius: 50%;
      // text-align: center;
      // font-size: 13px;
    }
  }
}
</style>
