import semverLte from 'semver/functions/lte';

export function isMinimumNativeVersion() {
  const minimumVersion = isIosApp() ? process.env.VUE_APP_IOS_MINIMUM_VERSION : process.env.VUE_APP_ANDROID_MINIMUM_VERSION;
  const currentVersion = getAppVersion().name;

  if (currentVersion === 'missing version') {
    return false;
  }

  return semverLte(minimumVersion, currentVersion);
}

export function headerIncludesAppId() {
  const identifier = 'com.nabogo';
  return (typeof window !== 'undefined' && window.navigator.userAgent.includes(identifier));
}

export function isIosApp() {
  return typeof webkit !== 'undefined' && headerIncludesAppId();
}

export function isAndroidApp() {
  return typeof nativeObj === 'object' && headerIncludesAppId();
}

export function isBrowser() {
  return !headerIncludesAppId();
}

export function isExpoApp() {
  return typeof window.ReactNativeWebView === 'object';
}

/**
 * @typedef {Object} AppVersion
 * @property {string} name
 * @property {int} build
 * @returns AppVersion
 */
export function getAppVersion() {
  const regex = /com.nabogo.*\/(\d+\.\d+\.\d+).*build (\d+)/g;
  const groups = [...window.navigator.userAgent.matchAll(regex)];

  if (groups.length === 0) {
    console.error('Useragent missing')
    return {
      name: 'missing version',
      build: 0,
    };
  }

  return {
    name: groups[0][1],
    build: parseInt(groups[0][2]),
  }
}
