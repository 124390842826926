<template>
  <n-bottom-sheet
    ref="sheet"
    type="header"
    :title="$t('main.provide.plannedRidesSheet.title')"
    no-layout
    scrollable
    fit-height
    @dismissed="dismissed"
  >
    <n-layout class="sheet-layout">
      <n-column
        :span="6"
        class="content-container"
      >
        <n-column :span="6" class="subtitle-container">
          <n-text
            preset="body"
            size="md-plus"
            weight="medium"
          >
            {{ $t('main.provide.plannedRidesSheet.subtitle') }}
          </n-text>
        </n-column>

        <n-column :span="6" class="option-container">
          <info-box
            :title="$t('main.provide.plannedRidesSheet.recurringRidesTitle')"
            :text="$t('main.provide.plannedRidesSheet.recurringRidesDescription')"
            bg-color="grey"
            icon-name="calendar-edit"
          />

          <n-button
            class="span-6 button"
            @click="onRecurringRidesClick"
          >
            {{ $t('main.provide.plannedRidesSheet.recurringRidesButton') }}
          </n-button>
        </n-column>

        <n-column :span="6" class="option-container">
          <info-box
            :title="$t('main.provide.plannedRidesSheet.singleRidesTitle')"
            :text="$t('main.provide.plannedRidesSheet.singleRidesDescription')"
            bg-color="grey"
            icon-name="calendar"
          />
          
          <n-button
            class="span-6 button"
            color="blue-light"
            @click="onSingleRideClick"
          >
            {{ $t('main.provide.plannedRidesSheet.singleRidesButton') }}
          </n-button>
        </n-column>
      </n-column>
    </n-layout>
  </n-bottom-sheet>
</template>

<script>
import { EventBus } from '@/vendor/events';
import InfoBox from '@/components/shared/information/infoBox';

export default {
  name: 'MainStartPlannedRidesSheet',
  components: {
    InfoBox,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    EventBus.$on('planned-rides-sheet-trigger', this.trigger);
    EventBus.$on('planned-rides-sheet-dismiss', this.dismiss);
  },
  beforeDestroy() {
    EventBus.$off('planned-rides-sheet-trigger', this.trigger);
    EventBus.$off('planned-rides-sheet-dismiss', this.dismiss);
  },
  methods: {
    trigger() {
      this.$refs?.sheet?.open();
    },
    dismiss() {
      this.$refs?.sheet?.dismiss();
    },
    dismissed() {
      // Reset any state if needed
    },
    onRecurringRidesClick() {
      this.$emit('recurringRides');
      this.dismiss();
    },
    onSingleRideClick() {
      this.$emit('singleRide');
      this.dismiss();
    },
    onForDriversSheetClick() {
      EventBus.$emit('for-drivers-sheet-trigger');
    },
  },
};
</script>

<style lang="scss" scoped>
.sheet-layout {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.content-container {
  flex: 1;
}

.subtitle-container {
  margin-bottom: 0.5rem;
}

.option-container {
  background-color: var(--color-grey-lightest);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style> 