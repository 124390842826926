/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_calendar-edit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path d="M8 2V5" stroke="#4C4A41" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M16 2V5" stroke="#4C4A41" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 9.08984H20.5" stroke="#4C4A41" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M19.21 15.7703L15.67 19.3103C15.53 19.4503 15.4 19.7103 15.37 19.9003L15.18 21.2503C15.11 21.7403 15.45 22.0803 15.94 22.0103L17.29 21.8203C17.48 21.7903 17.75 21.6603 17.88 21.5203L21.42 17.9803C22.03 17.3703 22.32 16.6603 21.42 15.7603C20.53 14.8703 19.82 15.1603 19.21 15.7703Z" stroke="#4C4A41" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.7 16.2812C19 17.3613 19.84 18.2013 20.92 18.5013" stroke="#4C4A41" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5V12" stroke="#4C4A41" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.9955 13.6992H12.0045" stroke="#4C4A41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.29431 13.6992H8.30329" stroke="#4C4A41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.29431 16.6992H8.30329" stroke="#4C4A41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
