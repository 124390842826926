/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_info-large': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<circle cx="13" cy="13" r="12.4" stroke="#746F62" stroke-width="1.2"/><path d="M13.0152 7.808C12.7378 7.808 12.5032 7.712 12.3112 7.52C12.1192 7.328 12.0232 7.09333 12.0232 6.816C12.0232 6.53867 12.1192 6.304 12.3112 6.112C12.5032 5.92 12.7378 5.824 13.0152 5.824C13.2818 5.824 13.5058 5.92 13.6872 6.112C13.8792 6.304 13.9752 6.53867 13.9752 6.816C13.9752 7.09333 13.8792 7.328 13.6872 7.52C13.5058 7.712 13.2818 7.808 13.0152 7.808ZM13.7192 9.232V18H12.2632V9.232H13.7192Z" fill="#746F62"/>'
  }
})





