/* eslint-disable no-shadow */
import Vue from 'vue';
import { browserLang, getLanguageWithFallback } from '@/i18n';
import debounce from 'lodash.debounce';
import userApi from '@/api/user';
import commuteApi from '@/api/commute';
import mapsApi from '@/api/maps';
import { EventBus } from '@/vendor/events';
import AppEvents from '@/vendor/event-types';
import userTypes from '@/store/modules/user-types';
import { namespacedTypes as appTypes } from '@/store/modules/app-types';
import * as Sentry from '@sentry/vue';
import i18n from '@/i18n';
import { isBrowser, isExpoApp } from '@/device';
import { isAfter, isWithinInterval, parseISO } from 'date-fns';
import { countryOptions } from '@/vendor/static-options';
import { send as nativeBridge } from '@/native-bridge';
import { EchoClient } from '@/vendor/echo';
import {
  resetFormbricks,
  triggerFormbricksAction,
  enqueueFormbricksInitEvent,
} from '@/plugins/Formbricks';
import { initHotjar } from '@/plugins/Hotjar';
import { sentry as SentryConfig } from '@/vendor/utils/config';

// initial state
const state = () => ({
  gmapHome: null,
  gmapWork: null,
  email: '',
  token: null,
  profile: {
    language: '',
    currency: '',
    phone_number: '',
    phoneCountry: '',
    has_mandatory_properties: true,
    incompleteTrips: 0,
    municipalityId: '',
    signupVoucher: '',
    vouchers: '',
  },
  notifications: [],
  playerId: null,
  create: {
    name: null,
    last_name: null,
    email: null,
    profilePicture: null,
    country: null,
    zip: null,
    phone: null,
    countryCode: null,
    terms: false,
    newsletter: false,
    phoneCode: null,
  },
  updating: {
    image: null,
    street: '',
    zipcode: '',
    city: '',
    phone_number: '',
    birth_year: '',
  },
  publicProfile: null,
  location: null,
  addressPredictions: [],
  notificationsCenter: [],
  voucher: null,
});

// getters
const getters = {
  [userTypes.GET_USER_TRACKING_ATTRIBUTES](state) {
    const {
      language,
      country,
      vouchers,
      signupVoucher,
      municipalityId,
      created_at,
      feature_flags,
      driverContractIds,
      passengerContractIds,
      stats
    } = state.profile;

    return {
      language,
      country,
      vouchers: vouchers ?? '',
      signupVoucher: signupVoucher ?? '',
      municipalityId: municipalityId + '',
      signUpDate: new Date(created_at).toLocaleDateString('en'),
      onDemandTripRemovalExperiment: feature_flags.OnDemandTripRemovalExperiment ?? '',
      plannedRidesExperiment: feature_flags.PlannedRidesExperiment ?? '',
      driverContractIds,
      passengerContractIds,
      seatsBooked: stats.seats_booked,
      passengersDriven: stats.seats_driven,
      tripsAsPassenger: stats.trips_passenger,
      tripsAsDriver: stats.trips_driver,
    };
  },
  [userTypes.HAS_FEATURE_FLAG](state) {
    return (featureFlag) => {
      const featureFlags = state.profile?.feature_flags;

      if (!featureFlags) {
        return false;
      }

      return !!featureFlags[featureFlag];
    };
  },
  [userTypes.FEATURE_FLAG_VALUE](state) {
    return (featureFlag) => {
      const featureFlags = state.profile?.feature_flags;

      if (!featureFlags) {
        return null;
      }

      return featureFlags[featureFlag] ?? null;
    };
  },
  addressPredictions(state) {
    return state.addressPredictions;
  },
  [userTypes.GET_TOKEN](state) {
    return state.token;
  },
  [userTypes.HAS_UNREAD_NOTIFICATION_FOR_NAME](state) {
    return (name, reference) => {
      for (let i = 0; i < state.notifications.length; i++) {
        const notification = state.notifications[i];
        // eslint-disable-next-line max-len
        if (
          notification.name === name &&
          notification.reference === reference.toString() &&
          notification.read_at === null
        ) {
          return true;
        }
      }
      return false;
    };
  },
  hasUnread(state) {
    return state.notifications && state.notifications.length > 0;
  },
  activeVacation(state) {
    if (
      state.profile.latestVacation &&
      isWithinInterval(new Date(), {
        start: parseISO(state.profile.latestVacation.start),
        end: parseISO(state.profile.latestVacation.end),
      })
    ) {
      return state.profile.latestVacation;
    }
    return null;
  },
  latestVacation(state) {
    if (
      state.profile.latestVacation &&
      isAfter(parseISO(state.profile.latestVacation.end), new Date())
    ) {
      return state.profile.latestVacation;
    }
    return null;
  },
  /**
   * @returns {UserLocation|null}
   */
  userLocation(state, getters, rootState, rootGetters) {
    return rootGetters['app/LAST_KNOWN_LOCATION'];
  },
  hasMandatoryProperties(state) {
    return state.profile?.has_mandatory_properties ?? true;
  },
  language(state) {
    return state.profile?.language ?? state.create?.language ?? browserLang;
  },
  isLoggedIn(state) {
    return state.token !== null;
  },
  profile(state) {
    return state.profile;
  },
};

// actions
const actions = {
  [userTypes.SIGN_IN_OTP]({ commit, dispatch }, { phone, otp }) {
    return userApi
      .signInWithOtp({ phone, otp })
      .then((result) => {
        commit(userTypes.UPDATED_TOKEN, result.token);
        commit(userTypes.UPDATED_PROFILE, result.profile);
        return result;
      })
      .then((result) => {
        dispatch(appTypes.SEND_REFERRALS, null, { root: true });

        if (result.profile.has_mandatory_properties) {
          nativeBridge.requestNotificationPermissionAndLogin(`${result.profile.id}`);
          dispatch(appTypes.DO_AFTER_LOGIN, null, { root: true });
        }

        return result;
      });
  },

  [userTypes.CLEAR_DATA]({ commit, state }) {
    commit(userTypes.CLEAR);
    commit('commute/CLEAR', null, { root: true });
    commit(userTypes.SELECT_LANGUAGE, {
      lang: browserLang,
      withDefaults: true,
    });
  },

  async [userTypes.SIGN_OUT]({ dispatch, state }) {
    try {
      await userApi.signOut(state.playerId);
    } catch (_) {
      // state.playerId is missing
    }

    resetFormbricks();
    EchoClient().leave(`users.${state.profile.id}`);
    if (isExpoApp()) {
      nativeBridge.userLogout();
    }
    return await dispatch(userTypes.CLEAR_DATA);
  },
  [userTypes.SIGN_OUT_QUIET]({ commit, state }) {
    return userApi.signOut(state.playerId).finally(() => {
      commit(userTypes.UPDATED_TOKEN, null);
      commit(userTypes.UPDATED_PROFILE, null);

      resetFormbricks();
      EchoClient().leave(`users.${state.profile.id}`);
      return null;
    });
  },
  [userTypes.DELETE_ACCOUNT]({ commit, dispatch }) {
    const result = userApi.delete().then((data) => {
      if (!data.code) {
        dispatch(userTypes.CLEAR_DATA);
      }
      return data;
    });

    triggerFormbricksAction('Action:UserDeleted:Success');
    return result;
  },
  [userTypes.READ_NOTIFICATIONS]({ commit, state }, query) {
    const reference = String(query.reference);
    const hasNotification = state.notifications.some((notification) => {
      if (query.type && query.type !== notification.type) {
        return false;
      }
      if (query.name && query.name !== notification.name) {
        return false;
      }
      if (query.reference && reference !== String(notification.reference)) {
        return false;
      }

      return true;
    });

    if (!hasNotification) {
      return new Promise((resolve) => resolve());
    }

    return userApi.readNotifications(query).then((result) => {
      commit(userTypes.UPDATED_NOTIFICATIONS, result.notifications);

      return result.notifications;
    });
  },
  [userTypes.PING_FETCH_PROFILE]: debounce(
    ({ dispatch, state }) => {
      if (!state.token) {
        return;
      }

      dispatch(userTypes.FETCH_PROFILE);
    },
    10000,
    {
      leading: true,
      trailing: true,
      maxWait: 12000,
    }
  ),
  [userTypes.FETCH_PROFILE]({ commit }) {
    return userApi.profileFetch().then((result) => {
      commit(userTypes.UPDATED_PROFILE, result);

      if (result.feature_flags.SurveyTarget) {
        enqueueFormbricksInitEvent('Action:TriggerSurvey');
      }

      initHotjar(result);
    });
  },
  async [userTypes.REGISTER_DEVICE]({ commit, state }, oneSignalPlayerId) {
    if (!state.token) {
      return;
    }

    await userApi.registerDevice(oneSignalPlayerId);
    commit(userTypes.UPDATED_ONESIGNAL_PLAYER_ID, oneSignalPlayerId);
  },
  [userTypes.FETCH_SINGLE_COMMUTE]({ commit }, driverTripId) {
    return commuteApi.fetchTripSingle(driverTripId).then((result) => {
      const { trip, notifications } = result;
      trip.departure_time = trip.planned_departure;
      trip.arrival_time = trip.planned_arrival;

      commit(userTypes.UPDATED_NOTIFICATIONS, notifications);

      return trip;
    });
  },
  [userTypes.FETCH_SINGLE_COMMUTE_REQUEST]({ commit }, requestId) {
    return commuteApi.fetchRequestSingle(requestId).then((result) => {
      const { request } = result;

      return request;
    });
  },
  [userTypes.UPDATE_PHONE]({ commit }, data) {
    return userApi.profilePhoneUpdate(data).then((result) => {
      commit(userTypes.PATCHED_PROFILE, result.data.user);
      return result;
    });
  },
  /* eslint-disable */
  [userTypes.CANCEL_COMMUTE_BOOKING](
    { commit, dispatch },
    { driverTripId, passengerTripId }
  ) {
    return commuteApi.cancelBooking(passengerTripId).then(async (result) => {
      await dispatch('commute/UPDATE_OVERVIEW', {}, { root: true });
      return result;
    });
  },
  [userTypes.DENY_COMMUTE_BOOKING](
    { commit },
    { driverTripId, passengerTripId }
  ) {
    return commuteApi.denyBooking(passengerTripId).then((result) => {
      return result;
    });
  },
  [userTypes.ACCEPT_COMMUTE_BOOKING](
    { commit },
    { driverTripId, passengerTripId }
  ) {
    return commuteApi.acceptBooking(passengerTripId).then((result) => {
      return result;
    });
  },
  [userTypes.ACCEPT_WITHOUT_INCOME_COMMUTE_BOOKING](
    { commit },
    { driverTripId, passengerTripId }
  ) {
    return commuteApi
      .acceptBookingWithoutIncome(passengerTripId)
      .then((result) => {
        return result;
      });
  },
  [userTypes.CANCEL_COMMUTE_TRIP]({ commit }, id) {
    return commuteApi.cancelTrip(id).then((result) => {
      return result;
    });
  },
  [userTypes.CANCEL_COMMUTE_REQUEST]({ commit }, id) {
    return commuteApi.cancelRequest(id).then((result) => {
      return result;
    });
  },
  [userTypes.FETCH_PUBLIC_PROFILE]({ commit }, id) {
    return userApi.fetchPublicProfile(id).then((result) => {
      commit(userTypes.UPDATED_PUBLIC_PROFILE, result);

      return result;
    });
  },
  async [userTypes.GMAP_PLACES]({ commit, state }) {
    const home = await mapsApi.reverseGeocodeLatLng({
      lat: state.profile.lat,
      lng: state.profile.lng,
    });
    const address = home.results[0];
    address.description = address.formatted_address;

    const work = await mapsApi.placeDetails(state.profile.workplace_pid);

    const prediction = {
      ...work.result,
    };

    if (prediction.geometry) {
      prediction.description = prediction.formatted_address;
    }
    commit(userTypes.UPDATED_GMAP_PLACES, { home: address, work: prediction });

    return { home: address, work: prediction };
  },
  [userTypes.FETCH_NOTIFICATIONS]({ commit }, page = 1) {
    return userApi.fetchNotifications(page).then((result) => {
      commit(userTypes.UPDATED_NOTIFICATION_CENTER, result);
      return result;
    });
  },
  [userTypes.MARK_ALL_NOTIFICATIONS_AS_READ]({ commit }) {
    return userApi.markAllNotificationsAsRead().then((result) => {
      commit(userTypes.MARK_ALL_NOTIFICATIONS_AS_READ);
      commit(userTypes.UPDATED_NOTIFICATION_CENTER, result);
      return result;
    });
  },
  [userTypes.MARK_ALL_MESSAGES_AS_READ]({ commit }) {
    return userApi.markAllMessagesAsRead().then((result) => {
      commit(userTypes.MARK_MESSAGES_AS_READ);
      return result;
    });
  },
  [userTypes.MARK_AS_READ]({ commit }, id) {
    commit(userTypes.UPDATED_SINGLE_NOTIFICATION, id);
    return userApi.markAsRead(id).then((result) => {
      return result;
    });
  },
  [userTypes.PROCESS_PAYOUT]({ commit, state }) {
    return userApi.processPayout().then((data) => {
      commit(userTypes.PATCHED_PROFILE, data);

      return data;
    });
  },
  async [userTypes.REQUEST_PHONE_AUTH_OTP]({ commit }, { phone, recaptcha }) {
    return await userApi.requestAuthOTP({
      type: 'phone',
      value: phone,
      recaptcha,
    });
  },
  async [userTypes.REQUEST_UPDATE_PHONE_OTP](
    { commit },
    { phone_number, recaptcha }
  ) {
    return await userApi.requestUpdatePhoneOTP(phone_number, recaptcha);
  },
  async [userTypes.REQUEST_PHONE_OTP]({ commit }, { phone, recaptcha }) {
    return await userApi.requestPhoneOTP(phone, recaptcha);
  },
  async [userTypes.REGISTER_USER](
    { commit, dispatch },
    { phoneNumber, otp, language, prospectId }
  ) {
    const response = await userApi.registerUser({
      phoneNumber,
      otp,
      language,
      prospectId,
    });
    commit(userTypes.UPDATED_TOKEN, response.token);
    commit(userTypes.PATCHED_PROFILE, response.profile);
    dispatch(appTypes.SEND_REFERRALS, null, { root: true });
    nativeBridge.sendEvent(AppEvents.USER_CREATED);
  },
  async [userTypes.UPDATE_MISSING_INFORMATION](
    { commit, dispatch, state },
    { firstName, lastName, email, image, country, zipcode, newsletter, voucher }
  ) {
    await dispatch(userTypes.PATCH_PROFILE_FORM, {
      image,
      name: firstName,
      last_name: lastName,
      email,
      image,
      country,
      zipcode,
      newsletter,
      voucher,
    });

    commit(userTypes.SET_VOUCHER, null);

    nativeBridge.requestNotificationPermissionAndLogin(`${state.profile.id}`);
    dispatch(appTypes.DO_AFTER_LOGIN, null, { root: true });
  },

  async [userTypes.PATCH_PROFILE]({ commit, state }, data) {
    const response = await userApi.patchProfile(data);
    commit(userTypes.PATCHED_PROFILE, response);
  },
  async [userTypes.PATCH_PROFILE_FORM]({ commit, state }, data) {
    const response = await userApi.patchProfileForm(data);
    commit(userTypes.PATCHED_PROFILE, response);
    return response;
  },
  async [userTypes.UPDATE_BANK_INFORMATION]({ commit, state }, data) {
    const response = await userApi.updateBankInformation(data);
    commit(userTypes.PATCHED_PROFILE, response);
    return response;
  },
  async [userTypes.LOCATION_FROM_ZIP]({ commit, state, dispatch }) {
    if (!state.profile.zipcode) {
      return null;
    }

    const searchTerm = `${state.profile.zipcode} ${
      countryOptions.find((x) => x.id === state.profile.country).text
    }`; // '7100 Danmark'
    const result = (await mapsApi.geocode(searchTerm)).results;
    if (result.length > 0) {
      const res = result[0];
      const location = {
        lat: res.geometry.location.lat,
        lng: res.geometry.location.lng,
      };
      dispatch(userTypes.PATCH_PROFILE, location);
      return `${res.geometry.location.lat}, ${res.geometry.location.lng}`;
    }
    return null;
  },
  [userTypes.SET_APP_LANGUAGE]({ commit, getters } ) {
    const lang = getters.isLoggedIn
      ? state.user.profile.language
      : browserLang;

    commit(userTypes.SELECT_LANGUAGE, {
      lang,
      withDefaults: true
    });
    console.log('SET_APP_LANGUAGE', lang);
  }
};

// mutations
const mutations = {
  [userTypes.UPDATED_TOKEN](state, token) {
    state.token = token;
  },
  [userTypes.UPDATED_PROFILE](state, profile) {
    if (profile && profile.unread_notifications) {
      const notifications = profile.unread_notifications;

      state.notifications = notifications.map((no) => ({
        ...no,
        data: JSON.parse(no.data),
      }));

      EventBus.$emit('notifications-updated', state.notifications);

      profile.unread_notifications = undefined;
    }

    state.profile = profile;
    state.profile.phoneCountry = '+45';

    if (
      state.profile.phone_number &&
      state.profile.phone_number.indexOf('+') === 0
    ) {
      state.profile.phoneCountry = state.profile.phone_number.substring(0, 3);
      state.profile.phone_number = state.profile.phone_number.substring(3);
    }

    if (SentryConfig.enabled) {
      Sentry.setUser({ id: state.profile.id });
      
      // Send user data to Expo app for Sentry
      if (isExpoApp()) {
        nativeBridge.setSentryUser({
          id: state.profile.id,
        });
      }
    }

    state.profile.incompleteTrips = profile.stats.incomplete_trips;

    i18n.locale = profile.language;
  },
  [userTypes.UPDATED_ONESIGNAL_PLAYER_ID](state, playerId) {
    state.playerId = playerId;
  },
  [userTypes.UPDATED_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications.map((no) => ({
      ...no,
      data: JSON.parse(no.data),
    }));

    EventBus.$emit('notifications-updated', state.notifications);
  },
  [userTypes.PATCHED_PROFILE](state, profile) {
    const keys = Object.keys(profile);
    for (let i = 0; i < keys.length; i++) {
      state.profile[keys[i]] = profile[keys[i]];
    }
    if (profile.language) {
      i18n.locale = profile.language;
    }
  },
  [userTypes.PATCHED_SIGN_UP](state, create) {
    const keys = Object.keys(create);

    for (let i = 0; i < keys.length; i++) {
      state.create[keys[i]] = create[keys[i]];
    }
  },
  /* eslint-disable-next-line */
  [userTypes.CLEAR](currentState) {
    const persistedValues = {
      playerId: currentState.playerId,
    };

    Object.assign(currentState, {
      ...state(),
      ...persistedValues,
    });
  },
  [userTypes.CLEAR_SIGN_UP](currentState) {
    currentState.create = state().create;
  },
  [userTypes.PATCHED_UPDATE](state, updating) {
    const keys = Object.keys(updating);

    for (let i = 0; i < keys.length; i++) {
      state.updating[keys[i]] = updating[keys[i]];
    }
  },
  [userTypes.UPDATED_PUBLIC_PROFILE](state, profile) {
    state.publicProfile = profile;
  },
  [userTypes.NEW_ADDRESS_PREDICTION](state, prediction) {
    //backwards compatibility
    if (!Array.isArray(state.addressPredictions)) {
      state.addressPredictions = Object.values(state.addressPredictions).sort(
        (a, b) => b.selectCount - a.selectCount
      );
    }

    const existingIndex = state.addressPredictions.findIndex(
      (x) => x.place_id == prediction.place_id
    );
    if (existingIndex !== -1) {
      state.addressPredictions.splice(existingIndex, 1);
    }

    state.addressPredictions.unshift(prediction);

    // terms is an array of the location parts.
    // Keep it over 3 to exclude locations like Vejle, Denmark.
    // Minimum precision is Street, City, Country
    state.addressPredictions = state.addressPredictions
      .filter(
        (item) =>
          typeof item.structured_formatting !== 'undefined' &&
          item.terms.length > 2
      )
      .map((x) => ({
        place_id: x.place_id,
        geometry: x.geometry,
        description: x.description,
        placeObject: x.placeObject,
        formatted_address: x.formatted_address,
        address_components: x.address_components,
        structured_formatting: x.structured_formatting,
        terms: x.terms,
      }))
      .slice(0, 5);

    return state.addressPredictions;
  },
  [userTypes.UPDATED_NOTIFICATION_CENTER](state, params) {
    params.data.map(
      (note) =>
        (note.data =
          typeof note.data === 'string' ? JSON.parse(note.data) : note.data)
    );
    if (params.current_page !== 1) {
      state.notificationsCenter = state.notificationsCenter.concat(params.data);
    } else {
      state.notificationsCenter = params.data;
    }
  },
  [userTypes.UPDATED_SINGLE_NOTIFICATION](state, id) {
    const note = state.notificationsCenter.find((x) => x.id === id);
    const index = state.notificationsCenter.indexOf(note);
    note.read_at = 'read';
    state.profile.unread_notifications_count -= 1;
    Vue.set(state.notificationsCenter, index, note);
  },
  [userTypes.MARK_ALL_NOTIFICATIONS_AS_READ](state) {
    state.notifications = [];
    state.profile.unread_notifications_count = 0;
  },
  [userTypes.MARK_MESSAGES_AS_READ](state) {
    state.notifications = state.notifications.filter(
      (x) => x.name != 'NewConversationMessage'
    );
  },

  [userTypes.SELECT_LANGUAGE](state, locale) {
    const language = getLanguageWithFallback(locale);

    locale.lang = language.id;

    // Caused problems on the profile page. I'm not exactly sure why.
    if (!locale.hasOwnProperty('skipSet') || locale.skipSet === false) {
      state.profile.language = locale.lang;
    }

    if (locale.withDefaults) {
      state.profile.currency = locale.currency;

      state.create.country = language.defaultCountryId;
      state.create.countryCode = language.defaultPhoneCountryId;
    }

    state.create.language = locale.lang;
    i18n.locale = locale.lang;
  },
  [userTypes.UPDATED_GMAP_PLACES](state, params) {
    state.gmapHome = params.home;
    state.gmapWork = params.work;
  },
  [userTypes.DELETED_WORKPLACE](state, params) {
    state.gmapWork = null;
  },
  [userTypes.SET_VOUCHER](state, voucher) {
    state.voucher = voucher;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
