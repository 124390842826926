<template>
  <n-full-screen overlay>
    <n-theme type="driver">
      <active-trip-navbar
        :passengers="passengerTrips.length"
        :endable="endable"
        :pausable="pausable"
        :seats="seats"
        :ending="ending"
        @pause="onPauseClick"
        @end="onEndClick"
      />

      <active-trip-sheet
        ref="activeTripSheet"
        :location-missing="locationMissing"
        :awaiting-location="awaitingLocation"
        :internet-missing="internetMissing"
        :near-destination="nearDestination"
        @sheet-visibility-change="onSheetVisibilityChange"
        @internet-missing-change="onInternetMissingChange"
        @near-destination-change="onNearDestinationChange"
        @retrieve-location="() => { throttledRetrieveLocation() }"
      />

      <portal
        v-if="showMarkersAndRoute"
        to="main-map"
      >
        <map-marker
          v-if="location && location.lat && location.lng"
          :key="getMarkerKey('od-location')"
          :position="location"
          type="plain-outline"
          theme="driver"
          faded
        />

        <map-marker
          v-if="persistedDestination && persistedDestination.lat && persistedDestination.lng"
          :key="getMarkerKey('od-destination')"
          :position="persistedDestination"
          theme="driver"
          type="end"
          faded
        />

        <map-route
          v-if="location && persistedDestination"
          key="od-route"
          :points="[location, persistedDestination]"
          theme="driver"
          dashed
          faded
        />
      </portal>

      <n-dialog
        ref="dialog"
        name="active-trip-dialog"
      />
    </n-theme>
  </n-full-screen>
</template>

<script>
import i18n from '@/i18n';
import store from '@/store';
import throttle from 'lodash.throttle';
import { Portal } from 'portal-vue';
import config from '@shared/config.json';
import { EventBus } from '@/vendor/events';
import ActiveTripSheet from './activeTripSheet';
import ActiveTripNavbar from './activeTripNavbar';
import { determineTripType } from '@/vendor/utils';
import MapRoute from '@/components/shared/map/route';
import MapMarker from '@/components/shared/map/marker';
import { getMapMarkerIncrementingId } from '@/vendor/maps';
import { isLocationWithinLifetime } from './support/location';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { EchoClient } from '@/vendor/echo';
import EventTypes from '@/vendor/event-types';

export default {
  name: 'MainActiveTrip',
  components: {
    Portal,
    MapRoute,
    MapMarker,
    ActiveTripSheet,
    ActiveTripNavbar
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('trip/refreshActiveTrip');
    // eslint-disable-next-line no-empty
    } catch (e) { }

    if (!to.params.selectedDestination && !store.state.trip?.activeDriverTrip?.id) {
      next('/');
      return;
    }

    next((vm) => {
      vm.resume();

      if (to.params.selectedDestination && store.state.trip?.activeDriverTrip) {
        vm.$error(
          i18n.t('error.user_has_no_ongoing_activity'),
        );
        next('/');
        return;
      }

      if (vm.selectedDestination) {
        vm.setDestination(vm.selectedDestination);
      }

      vm.onPointsOfInterestUpdate();
      vm.retrieveLocation();

      if (to.params.forceEndTrip) {
        vm.onEndClick();
      }

      vm.initialLocationSent = false;
    });
  },
  props: {
    selectedDestination: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ending: false,
      creating: false,
      sheetBottom: 200,
      sheetVisible: false,
      nearDestination: false,
      internetMissing: false,
      awaitingLocation: true,
      initialLocationSent: false,
      awaitingLocationTimeout: null,
      showMarkersAndRoute: false,
      isSending: false,
    };
  },
  computed: {
    ...mapState('trip', [
      'activeDriverTrip',
      'location',
      'locationUpdatedAt',
      'subsidisable',
      'distance',
      'destination',
    ]),
    ...mapState('app', [
      'lastKnownLocationUpdatedAt',
    ]),
    ...mapGetters('app', {
      lastKnownLocation: 'LAST_KNOWN_LOCATION',
    }),
    /**
     * @returns {UserLocation|null}
     */
    persistedDestination() {
      const tripLocation = this.activeDriverTrip ? { lat: this.activeDriverTrip.to_lat, lng: this.activeDriverTrip.to_lng } : null;
      const localLocation = this.destination ? { lat: this.destination.lat, lng: this.destination.lng } : null;

      if (this.activeDriverTrip) {
        return tripLocation;
      }

      if (this.destination) {
        return localLocation;
      }

      return null;
    },
    locationMissing() {
      return !this.location || !this.hasRecentLocation();
    },
    passengerTrips() {
      return (
        this.activeDriverTrip?.passenger_trips?.filter(
          (passengerTrip) => passengerTrip.status === 'ACCEPTED'
        ) || []
      );
    },
    seats() {
      return 4;
    },
    pausable() {
      return Boolean(this.activeDriverTrip?.id);
    },
    endable() {
      return !this.locationMissing
        && !this.internetMissing
        && this.subsidisable;
    },
    routePoints() {
      if (
        !this.location?.position ||
        !this.persistedDestination?.position
      ) {
        return null;
      }

      return [
        this.location.position,
        this.persistedDestination.position,
      ];
    },
    throttledRetrieveLocation() {
      return throttle(function () {
        this.retrieveLocation();
      }, 10 * 1000, { leading: true })
    },
  },
  watch: {
    sheetVisible: 'onPointsOfInterestUpdate',
    '$store.state.activeTrip.location': 'onPointsOfInterestUpdate',
    '$store.state.activeTrip.destination': 'onPointsOfInterestUpdate',
    locationMissing: 'onEndableChange',
    internetMissing: 'onEndableChange',
    subsidisable: 'onEndableChange',
  },
  mounted() {
    EventBus.$on('map-ready', this.onPointsOfInterestUpdate);
    EventBus.$on('location', this.onLocationUpdate);
    EventBus.$on('notification', this.refreshActiveTrip);
    EventBus.$on('window-focus', this.onWindowFocus);

    EchoClient()
      .private(`users.${store.state.user.profile.id}`)
      .listen('PassengerJoinedTrip', this.refreshActiveTrip)
  },
  beforeDestroy() {
    this.throttledRetrieveLocation.cancel();

    EventBus.$off('map-ready', this.onPointsOfInterestUpdate);
    EventBus.$off('location', this.onLocationUpdate);
    EventBus.$off('notification', this.refreshActiveTrip);
    EventBus.$off('window-focus', this.onWindowFocus);
  },
  methods: {
    ...mapMutations('trip', ['pause', 'resume', 'setOrigin', 'setDestination']),
    ...mapActions('trip', ['createTrip', 'flushUpdateLocationThrottle', 'throttledUpdateLocation', 'useLocation', 'refreshActiveTrip', 'endTrip']),
    ...mapActions('app', {
      getCurrentLocation: 'GET_CURRENT_LOCATION',
    }),
    getMarkerKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return getMapMarkerIncrementingId(id);
    },
    onSheetVisibilityChange(visible) {
      this.sheetVisible = visible;
    },
    onInternetMissingChange(missing) {
      this.internetMissing = missing;
    },
    onNearDestinationChange(near) {
      this.nearDestination = near;
    },
    hasRecentLocation() {
      if (!this.location || !this.locationUpdatedAt) {
        return false;
      }

      return isLocationWithinLifetime(this.locationUpdatedAt);
    },
    async onLocationUpdate(location, updatedAt = null) {
      if (this.creating) {
        return;
      }

      let recentlyCreated = false;

      if (!this.activeDriverTrip?.id) {
        try {
          await this.setOriginAndCreateTrip(location);

          recentlyCreated = true;
        } catch (e) {
          if (e.handled) {
            return;
          }
          if (e?.response?.data?.message === 'user_has_ongoing_activity') {
            this.$error(
              i18n.t('error.user_has_ongoing_activity'),
            );

            this.$router.resetToHome();
          } else {
            console.error(e);
            this.$error();
          }

          return;
        }
      }

      if (updatedAt === null) {
        updatedAt = new Date();
      }

      const locationUpdatedPromise = this.throttledUpdateLocation(location, updatedAt);

      if (recentlyCreated) {
        this.flushUpdateLocationThrottle();
      }

      await locationUpdatedPromise;

      this.initialLocationSent = true;
      this.awaitingLocation = false;

      this?.$refs?.activeTripSheet?.requestCodeWhenMissing();
    },
    /**
     * @param {UserLocation} location
     */
    async setOriginAndCreateTrip(location) {
        if (this.creating || this.ending) {
        return;
      }

      this.setOrigin(location);

      this.creating = true;

      try {
        await this.createTrip();
        // eslint-disable-next-line no-useless-catch
      } catch (e) {
        throw e;
      } finally {
        this.creating = false;
      }
    },
    onPointsOfInterestUpdate() {
      const poi = [];

      if (this.location) {
        poi.push(Object.assign({}, this.location));
      }

      if (this.persistedDestination) {
        poi.push(Object.assign({}, this.persistedDestination));
      }

      if (!poi?.length) {
        return;
      }

      EventBus.$emit('map-pan-to', poi, {
        top: 70,
        bottom: 50 + (this.sheetVisible ? this.sheetBottom : 0),
      });

      this.$nextTick(() => {
        this.showMarkersAndRoute = true;
      });
    },
    onWindowFocus() {
      if (this.creating) {
        return;
      }

      console.log('[active trip] window focus event triggered');

      this.throttledRetrieveLocation();
      this.refreshActiveTrip();
    },
    retrieveLocation() {
      if (this.ending) {
        return;
      }

      console.log('[active trip] retrieving location');

      this.getCurrentLocation();

      // use last known valid and recent location when entering the screen
      const lastKnownLocation = !this.initialLocationSent
        ? this.lastKnownLocation
        : null;

      if (lastKnownLocation !== null && isLocationWithinLifetime(this.lastKnownLocationUpdatedAt)) {
        console.log('[active trip] using last known location');

        this.onLocationUpdate(
          lastKnownLocation,
          this.lastKnownLocationUpdatedAt
        );

        return;
      }

      this.awaitLocationWithTimeout();
    },
    awaitLocationWithTimeout(seconds = 5) {
      clearTimeout(this.awaitingLocationTimeout);

      this.awaitingLocation = true;

      // wait for next render cycle to ensure that the app is in the foreground
      window.requestAnimationFrame(() => {
        clearTimeout(this.awaitingLocationTimeout);

        this.awaitingLocationTimeout = setTimeout(() => {
          this.awaitingLocation = false;
        }, seconds * 1000);
      });
    },
    onPauseClick() {
      this.pause();
      this.$router.resetToHome();
    },
    onEndableChange() {
      const endingDialogVisible = this.$refs.dialog?.open && this.$refs.dialog?.params?.color;

      if (endingDialogVisible) {
        this.$refs.dialog.close();

        setTimeout(() => {
          this.onEndClick();
        }, 300);
      }
    },
    onEndClick() {
      const proceedHandler = async (userExpectsSubsidy) => {
        if (this.activeDriverTrip?.id) {
          if (this.ending) {
            return;
          }

          this.ending = true;

          try {
            const type = determineTripType(this.activeDriverTrip);
            await this.endTrip({ userExpectsSubsidy, trip_id: this.activeDriverTrip.id });

            EventBus.$emit('trip-event', { 'action': EventTypes.TRIP_COMPLETED, type });
          } catch (e) {
            if (e.response?.status === 409) {
              this.retrieveLocation();
              this.$error();
              return;
            }

            console.error(e);
            this.$error();
          } finally {
            this.$nextTick(() => {
              if (this?.ending) {
                this.ending = false;
              }
            });
          }
        }

        EventBus.$emit('map-zoom-to-min-level');

        this.$router.resetToHome();
      };

      if (this.internetMissing) {
        this.showInternetMissingModal(() => proceedHandler(false));
      } else if (this.passengerTrips.length === 0) {
        proceedHandler(false);
      } else if (this.locationMissing) {
        this.showLocationMissingModal(() => proceedHandler(false));
      } else if (this.subsidisable) {
        this.showWithinDestinationModal(() => proceedHandler(true));
      } else {
        this.showOutsideDestinationModal(() => proceedHandler(false));
      }
    },
    showInternetMissingModal(proceed) {
      this.$modal.show('active-trip-dialog', {
        title: this.$t('main.activeTrip.modalEndTripInvalidInternetMissing.header'),
        text: this.$t('main.activeTrip.modalEndTripInvalidInternetMissing.body'),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t('main.activeTrip.modalEndTripInvalidInternetMissing.ok'),
          handler: proceed,
        },
        cancelButton: {
          text: this.$t('main.activeTrip.modalEndTripInvalidInternetMissing.cancel'),
        }
      });
    },
    showLocationMissingModal(proceed) {
      this.$modal.show('active-trip-dialog', {
        title: this.$t('main.activeTrip.modalEndTripInvalidLocationMissing.header'),
        text: this.$t('main.activeTrip.modalEndTripInvalidLocationMissing.body'),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t('main.activeTrip.modalEndTripInvalidLocationMissing.ok'),
          handler: proceed,
        },
        cancelButton: {
          text: this.$t('main.activeTrip.modalEndTripInvalidLocationMissing.cancel'),
        }
      });
    },
    showWithinDestinationModal(proceed) {
      this.$modal.show('active-trip-dialog', {
        title: this.$t('main.activeTrip.modalEndTripValid.header'),
        text: this.$t('main.activeTrip.modalEndTripValid.body'),
        color: 'success',
        success: {
          text: this.$t('main.activeTrip.modalEndTripValid.ok'),
          handler: proceed,
        },
        cancelButton: {
          text: this.$t('main.activeTrip.modalEndTripValid.cancel'),
        }
      });
    },
    showOutsideDestinationModal(proceed) {
      this.$modal.show('active-trip-dialog', {
        title: this.$t('main.activeTrip.modalEndTripInvalidOutsideDestination.header'),
        text: this.$t('main.activeTrip.modalEndTripInvalidOutsideDestination.body', {
          meters: config.on_demand.subsidy_distance_meters,
          distance: this.distance.toLocaleString() ?? '> ' + config.on_demand.subsidy_distance_meters,
        }),
        cancel: true,
        color: 'error',
        cancelButton: {
          text: this.$t('main.activeTrip.modalEndTripInvalidOutsideDestination.cancel'),
        },
        successButton: {
          text: this.$t('main.activeTrip.modalEndTripInvalidOutsideDestination.ok'),
          color: 'error',
          handler: proceed,
        },
        toggle: {
          enabled: true,
          text: this.$t('main.activeTrip.modalEndTripInvalidOutsideDestination.subsidyText'),
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
