<template>
  <n-bottom-sheet type="header" :title="$t('accountPayout.accountInformationSheet.title')" class="sheet" dismissible :closed="true" ref="sheet" @dismissed="$emit('dismissed')">
    <n-text block preset="label-1" color="accent">
      {{ $t('accountPayout.accountInformationSheet.title') }}
    </n-text>

    <n-text block preset="body" color="grey-dark">
      {{ $t('accountPayout.accountInformationSheet.description') }}
    </n-text>

    <n-grid v-if="type === 'IBAN'" :y-gap="4">
      <n-input
        v-model="ibanNo"
        icon="pencil"
        :placeholder="$t('accountPayout.accountInformationSheet.ibanNo')"
      />
    </n-grid>

    <n-grid v-if="type === 'REG_ACCOUNT'" :y-gap="4">
      <n-input
        v-model="regNumber"
        icon="pencil"
        :placeholder="$t('accountPayout.accountInformationSheet.registrationNo')"
      />
      <n-input
        v-model="accountNumber"
        icon="pencil"
        :placeholder="$t('accountPayout.accountInformationSheet.accountNo')"
      />
    </n-grid>

    <n-button
      class="span-6"
      :loading="saving"
      @click="save"
      size="lg">
      {{ $t('accountPayout.accountInformationSheet.save') }}
    </n-button>
  </n-bottom-sheet>
</template>

<script>
import { EventBus } from '@/vendor/events';
import { mapState, mapActions } from 'vuex';
import { namespace as userNamespace, namespacedTypes as userTypes } from '@/store/modules/user-types';

export default {
  data: () => {
    return {
      ibanNo: '',

      regNumber: '',
      accountNumber: '',

      saving: false,
    }
  },
  computed: {
    ...mapState(userNamespace, [
      'profile',
    ]),
    type() {
      return this.profile.bank_information_type;
    },
    data() {
      if (this.type === 'IBAN') {
        return {
          iban: this.ibanNo.replace(/\s/gm, ''),
        };
      }

      if (this.type === 'REG_ACCOUNT') {
        return {
          reg_number: this.regNumber,
          account_number: this.accountNumber,
        };
      }
    },
  },
  methods: {
    ...mapActions({
      updateBankInformation: userTypes.UPDATE_BANK_INFORMATION,
    }),
    open() {
      this.$refs.sheet.open();

      this.ibanNo = this.profile.iban_number;

      this.regNumber = this.profile.reg_number;
      this.accountNumber = this.profile.account_number;
    },
    save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      this.updateBankInformation(this.data)
        .then(() => {
          this.$refs.sheet.dismiss();
        })
        .finally(() => {
          this.saving = false;
        })
        .catch((error) => {
          if (error.response?.status === 422 && error.response?.data.errors) {
            const message = getValidationErrorMessage(error);
            return this.$error(message);
          }
        });
    },
  }
}
</script>
