<template>
  <div class="span-6">
    <div class="recaptcha">
      <vue-recaptcha
        ref="recaptcha"
        :sitekey="recaptchaSiteKey"
        :load-recaptcha-script="true"
        :language="language"
        size="invisible"
        @verify="onVerify"
        @expired="onExpired"
      />
    </div>

    <n-button
      :loading="loading || responding"
      v-bind="$attrs"
      @click="onClick"
    >
      <slot />
    </n-button>
  </div>
</template>

<script>
import store from '@/store';
import { VueRecaptcha } from 'vue-recaptcha';
import { recaptcha } from '@/vendor/utils/config';

export default {
  components: {
    VueRecaptcha
  },
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      response: null,
      responding: false
    };
  },
  computed: {
    recaptchaSiteKey() {
      return recaptcha.siteKey;
    },
    language() {
      return store.state.user.profile?.language || 'en';
    }
  },
  methods: {
    async onClick() {
      if (!recaptcha.enabled) {
        this.$emit('click');
        return;
      }

      if (this.responding) {
        return;
      }

      if (this.response) {
        this.$emit('click', this.response);
        return;
      }

      this.responding = true;

      await this.awaitRecaptchaLoaded();

      this.$refs?.recaptcha?.execute();
    },
    async awaitRecaptchaLoaded() {
      const recaptcha = window.grecaptcha || {};

      if (typeof recaptcha.ready !== 'function') {
        recaptcha.ready = function(callback) {
          if (typeof grecaptcha === 'undefined') {
            window.___grecaptcha_cfg = window.___grecaptcha_cfg || {};
            window.___grecaptcha_cfg.fns = window.___grecaptcha_cfg.fns || [];

            window.___grecaptcha_cfg.fns.push(callback);
          } else {
            callback();
          }
        };
      }

      return new Promise((resolve) => {
        recaptcha.ready(resolve);
      });
    },
    onVerify(response) {
      this.responding = false;
      this.response = response;

      this.$emit('click', response);
    },
    onExpired() {
      this.responding = false;
      this.reset();
    },
    reset() {
      this.response = null;
      this.$refs?.recaptcha?.reset();
    }
  }
};
</script>

<style>
iframe[src*='recaptcha'] {
  position: absolute;
  bottom: 0;
  margin-bottom: 2rem;
}

div:has(> iframe[src*='recaptcha']) {
  height: 100vh !important;
}
</style>
