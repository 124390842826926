<template>
  <div>
    <div
      v-show="isOpen"
      :class="['overlay-container', { active: isOpen }]"
      @click.self="close"
    >
      <n-bottom-safe-spacer
        id="datetime-picker-container"
        :min="30"
      >
        <n-grid :y-gap="0">
          <n-column
            :span="6"
            class="layout"
          >
            <div class="title-box">
              <div class="title-line">
                <n-text-model
                  class="title-text"
                  weight="bold"
                >
                  {{ title }}
                </n-text-model>
                <svg-icon
                  name="close"
                  class="close-icon"
                  color="#CDCDCD"
                  @click="close"
                />
              </div>
              <div
                v-if="userRole === 'driver' && !onlyTime"
                class="radio-group"
              >
                <n-input-radio
                  v-model="timePreset"
                  name="departure-type"
                  value="now"
                >
                  <n-text preset="body">
                    {{ $t('c.searchHeader.leaveNow') }}
                  </n-text>
                </n-input-radio>
                <n-input-radio
                  v-model="timePreset"
                  name="departure-type"
                  value="pick"
                >
                  <n-text preset="body">
                    {{ $t('c.searchHeader.pickTime') }}
                  </n-text>
                </n-input-radio>
              </div>
              <n-text
                v-if="userRole === 'passenger'"
                preset="body"
              >
                {{ description ?? $t(`c.searchHeader.timePicker.description.${mode}`) }}
              </n-text>
            </div>
            <div
              v-if="userRole === 'passenger' && displayStartTimeOptions"
              class="tabs"
            >
              <button
                :class="['tab ', mode === 'departure' ? 'mode-selected' : 'mode-unselected']"
                @click="selectMode('departure')"
              >
                {{ $t('c.searchHeader.timePicker.modes.departure') }}
              </button>
              <button
                :class="['tab', mode === 'arrival' ? 'mode-selected' : 'mode-unselected']"
                @click="selectMode('arrival')"
              >
                {{ $t('c.searchHeader.timePicker.modes.arrival') }}
              </button>
            </div>

            <div class="wheel-container">
              <n-datetime-wheel
                v-if="userRole === 'passenger' || timePreset === 'pick' || onlyTime"
                :time-selected="timeSelected"
                :only-time="onlyTime"
                @datetimeChange="updateDatetime"
              />
              <n-button
                class="save-button"
                :text-uppercase="false"
                @click="save"
              >
                {{ $t('c.searchHeader.timePicker.save') }}
              </n-button>
            </div>
          </n-column>
        </n-grid>
      </n-bottom-safe-spacer>
    </div>
    <div
      v-show="hasBackDrop"
      class="backdrop"
      @click="close"
    />
  </div>
</template>

<script>

import { differenceInMinutes } from 'date-fns';

export default {
  name: 'DatetimePicker',
  props: {
    open: {
      type: Boolean,
      default: true
    },
    timeSelected: {
      type: Date,
      default: null
    },
    timeMode: {
      type: String,
      default: 'departure'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    },
    onlyTime: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: 'passenger'
    },
    // if enabled, allows for searching by departure and arrival time
    displayStartTimeOptions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: this.open,
      mode: this.timeMode,
      datetime: this.timeSelected,
      timePreset: this.onlyTime ? 'pick' : differenceInMinutes(this.timeSelected, new Date()) <= 5 ? 'now' : 'pick',
      hasBackDrop: true
    };
  },
  methods: {
    save() {
      if (this.userRole === 'driver' && this.timePreset === 'now' && !this.onlyTime) {
        this.datetime = new Date();
      }
      this.$emit('datetimeChange', {
        mode: this.mode,
        dateTime: this.datetime
      });

      this.close();
    },
    close() {
      if (!this.isOpen) {
        return;
      }

      const elem = document.getElementById('datetime-picker-container');
      elem.style.transition = 'transform 0.3s ease-out';
      elem.style.transform = 'translateY(100%)';

      this.hasBackDrop = false;

      setTimeout(() => {
        this.isOpen = false;
        this.$emit('dateTimePickerClosed');
      }, 300);
    },
    selectMode(mode) {
      this.mode = mode;
      this.$emit('modeChange', mode);
    },
    updateDatetime($event) {
      this.datetime = $event;
    }
  }

};
</script>


<style lang="scss" scoped>
.overlay-container {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-container.active {
  width: 100%;
  animation: slideUp 0.3s forwards;
}

@keyframes slideUp {

  from {
    translate: 0 100vh;
  }

  to {
    translate: 0 0;
  }
}

#datetime-picker-container {
  position: fixed;
  z-index: 9998;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 20px 20px 0 0;
}

.backdrop {
  top: 0;
  left: 0;
  opacity: 0.25;
  width: 100vw;
  z-index: 9997;
  height: 100vh;
  position: fixed;
  background-color: black;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .title-text {
    font-size: 16px;
  }
}

.close-icon {
  width: 24px;
  height: 24px;
}

.title-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 15px;
}

.tabs {
  width: 100%;
  display: flex;
}

.tab {
  width: 100%;
  height: 32px;
  border: none;
  border-bottom: 1px black solid;
  padding-bottom: 20px;
  background: none;
  font-size: var(--text-sm-font-size);
  line-height: var(--text-sm-line-height);
}

.mode-selected {
  border-bottom-width: 3px;
  color: var(--color-grey-darker);
}

.mode-unselected {
  color: var(--color-grey);
  border-color: var(--color-grey);
}

.wheel-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px
}

.save-button {
  width: 100%;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
</style>
