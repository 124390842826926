<template>
  <div class="container">
    <n-text
      preset="sub"
    >
      Phone Number*
    </n-text>
    <div class="input-container">
      <icon-dropdown
        class="country-code-input"
        :value="phoneCountryOption"
        :options="phoneCountryOptions"
        @input="onCountryCodeChange"
      />
      <n-input
        ref="phoneInput"
        v-model="phoneNumber"
        type="tel"
        class="phone-number-input"
        :placeholder="$t('main.start.login.phone')"
        @input="onPhoneNumberChange($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { getPhoneCountry, phoneCountryOptions } from '@/vendor/static-options';
import store from '@/store';
import IconDropdown from '@/components/core/inputs/iconDropdown.vue';

export default {
  name: 'PhoneNumberInput',
  components: { IconDropdown },
  data() {
    return {
      phoneCountryOptions: phoneCountryOptions,
      selectedCountryOption: null,
      phoneNumber: null,
    };
  },
  computed: {
    phoneCountryOption() {
      if (this.selectedCountryOption) {
        return getPhoneCountry(this.selectedCountryOption.countryId);
      }
      return getPhoneCountry(store.state.user.create.country);
    }
  },
  methods: {
    onPhoneNumberChange(number) {
      this.phoneNumber = number;
      this.emitChanges();
    },
    onCountryCodeChange(countryCode) {
      this.selectedCountryOption = getPhoneCountry(countryCode.countryId);
      this.emitChanges();
    },
    emitChanges() {
      this.$emit('valueChanged', {
        phoneCountryCode: this.phoneCountryOption.id,
        phoneNumber: this.phoneNumber,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.input-container {
  display: flex;
  gap: 0.38rem;
}
.country-code-input {
  flex: 0.25;
}
.phone-number-input {
  flex: 0.75;
}
</style>
