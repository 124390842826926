<template>
  <n-column :span="6" class="recaptcha-terms">
    <n-text preset="sub" color="grey-dark" style="text-align: center;">
      <i18n path="auth.recaptcha.terms" tag="div">
        <template v-slot:privacyLink><a class="link link-subtle" @click="openLink($t('auth.recaptcha.privacyUrl'))">{{$t('auth.recaptcha.privacyLink')}}</a></template>
        <template v-slot:termsLink><a class="link link-subtle" @click="openLink($t('auth.recaptcha.termsUrl'))">{{$t('auth.recaptcha.termsLink')}}</a></template>
      </i18n>
    </n-text>
  </n-column>
</template>

<script>
import { isExpoApp } from '@/device';
import nativeBridge from '@/native-bridge';

export default {
  methods: {
    openLink(url) {
      if (isExpoApp) {
        return nativeBridge.send.openInAppUrl(url);
      }
      return window.open(url, '_blank');
    }
  }
}
</script>

<style>
.grecaptcha-badge {
  visibility: hidden !important;
}
</style>

<style lang="scss" scoped>
.recaptcha-terms {
  margin-top: 0.75rem;
}

.recaptcha-terms > span {
  display: block;
}
</style>
