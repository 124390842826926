var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drawer-wrapper"},[_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"drawer"},[_c('n-layout',[_c('n-grid',[_c('n-text',{staticClass:"span-5",attrs:{"preset":"title","color":"accent"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.title'))+" ")]),_c('n-icon',{staticClass:"flex-end feedback",attrs:{"name":"cross","color":"accent","outline":""},on:{"click":_vm.close}})],1),_c('div',{staticClass:"span-6 scrollable"},[_c('n-grid',{attrs:{"y-gap":5},nativeOn:{"click":function($event){return _vm.to('profile')}}},[_c('profile-card',_vm._b({attrs:{"has-eid-enabled":_vm.hasEidEnabled}},'profile-card',_vm.profile,false))],1),_c('n-grid',{staticClass:"menu-items"},[_c('div',{staticClass:"menu-item feedback",on:{"click":function($event){return _vm.to('profile')}}},[_c('n-text',{attrs:{"preset":"title"}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.profile'))+" ")])],1),_c('span',{class:{
                notice: _vm.notifications.some((x) => x.type.includes('message')),
              }}),_c('div',{staticClass:"span-5 feedback",on:{"click":function($event){return _vm.to('conversations')}}},[_c('n-text',{attrs:{"preset":"title"},on:{"click":function($event){return _vm.to('conversations')}}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.messages'))+" ")])],1),_c('span',{class:{
                notice: _vm.notifications.some((x) => !x.type.includes('message')),
              }}),_c('div',{staticClass:"span-5 feedback",on:{"click":function($event){return _vm.to('notifications')}}},[_c('n-text',{attrs:{"preset":"title"},on:{"click":function($event){return _vm.to('notifications')}}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.notifications'))+" ")])],1),_c('div',{staticClass:"menu-item feedback",on:{"click":function($event){return _vm.to('trip-history')}}},[_c('n-text',{attrs:{"preset":"title"}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.tripHistory'))+" ")])],1),_c('div',{staticClass:"menu-item feedback",on:{"click":function($event){return _vm.to('account')}}},[_c('n-text',{attrs:{"preset":"title"}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.myAccount'))+" ")])],1),_c('span',{class:{
                'active-vacation': !!_vm.$store.getters['user/activeVacation'],
              }}),_c('div',{staticClass:"menu-item feedback",on:{"click":function($event){return _vm.to('vacation')}}},[_c('n-text',{attrs:{"preset":"title"}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.vacation'))+" ")])],1),_c('div',{staticClass:"menu-item feedback",on:{"click":_vm.showHowto}},[_c('n-text',{attrs:{"preset":"title"}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.howTo'))+" ")])],1),_c('div',{staticClass:"menu-item feedback",on:{"click":_vm.openHelp}},[_c('n-text',{attrs:{"preset":"title"}},[_vm._v(" "+_vm._s(_vm.$t('main.nav.help'))+" ")])],1)])],1)],1)],1)]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"backdrop",on:{"click":_vm.close}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }