// @ts-check
import formbricks from "@formbricks/js";
import store from '@/store';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';

let formbricksInitiated = false;
const formbricksBackdrops = [];
let formbricksInitQueue = [];

export const initFormbricks = async () => {
  if (typeof window === 'undefined' || formbricksInitiated) {
    return;
  }

  if (
    !process.env.VUE_APP_FORMBRICKS_ENVIRONMENT_ID ||
    !process.env.VUE_APP_FORMBRICKS_API_HOST
  ) {
    console.warn('No environment set up for formbricks');
    return;
  }

  const user = store.state.user.profile;
  if (!user.id) {
    console.warn('No user provided for formbricks');
    return;
  }

  // Ensure we don't have a formbricks session stored in localStorage
  if (localStorage.getItem('formbricks-js')) {
    localStorage.removeItem('formbricks-js');
  }
  
  const { id } = user;
  const attributes = store.getters[userTypes.GET_USER_TRACKING_ATTRIBUTES];

  await formbricks.init({
    environmentId: process.env.VUE_APP_FORMBRICKS_ENVIRONMENT_ID,
    apiHost: process.env.VUE_APP_FORMBRICKS_API_HOST,
    userId: id + '',
    attributes,
  });

  formbricksInitiated = true;

  // Process any queued events after initialization
  if (formbricksInitQueue.length > 0) {
    if (process.env.NODE_ENV !== 'production') {
      console.debug('[Formbricks] Processing queued events:', formbricksInitQueue);
    }

    // Process each queued event one by one
    for (const eventName of formbricksInitQueue) {
      try {
        await triggerFormbricksAction(eventName);
      } catch (error) {
        console.error(`[Formbricks] Error processing queued event ${eventName}:`, error);
      }
    }

    // Clear the queue after processing
    formbricksInitQueue = [];
  }
};

export const reinitFormbricks = async () => {
  if (formbricksInitiated) {
    formbricksInitiated = false;
    await formbricks.reset();
  } else {
    console.warn('[Formbricks] Formbricks was never initialized. Initializing now.');
  }

  initFormbricks();
}

export function enqueueFormbricksInitEvent(event) {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(`[Formbricks] Enqueueing event: ${event}, initialized: ${formbricksInitiated}`);
  }

  if (formbricksInitiated) {
    // If Formbricks is already initialized, trigger the event immediately
    triggerFormbricksAction(event);
    return;
  }

  // Otherwise, add to queue if not already in queue
  if (!formbricksInitQueue.includes(event)) {
    formbricksInitQueue.push(event);
  }
}

export const resetFormbricks = () => {
  if (typeof window === 'undefined') {
    return;
  }

  formbricks.logout();
  formbricksInitiated = false;
};
/**
 *
 * @param {string} eventName
 * @returns
 */
export const triggerFormbricksAction = async (eventName) => {
  if (typeof window === 'undefined') {
    console.warn('Formbricks cannot be used in server-side rendering');
    return;
  }

  if (!formbricksInitiated) {
    console.warn(`Formbricks has not been initiated, queueing event: ${eventName}`);
    enqueueFormbricksInitEvent(eventName);
    return;
  }

  overwriteFormbricksTriggerForBackdrop();

  if (process.env.NODE_ENV !== 'production') {
    console.debug('[Formbricks] Tracking:', eventName);
  }

  // TODO: Once Formbricks has implemented survey attributes, we should send attributes in track() function.
  // We need to trigger the event ASAP, so we don't wait for the attribute API calls to finish.
  try {
    await formbricks.track(eventName);
  } catch (error) {
    console.error(`[Formbricks] Error tracking event ${eventName}:`, error);
  }
};

const overwriteFormbricksTriggerForBackdrop = () => {
  const backdrop = document.querySelector(
    '.formbricks-form > div > div.relative'
  );
  const closeButton = document.querySelector(
    '.formbricks-form button.text-close-button'
  );

  // wait for the backdrop to be available
  if (!backdrop || !closeButton) {
    return setTimeout(() => {
      overwriteFormbricksTriggerForBackdrop();
    }, 250);
  }

  // avoid setting the event listener multiple times
  if (formbricksBackdrops.includes(backdrop)) {
    return;
  }

  formbricksBackdrops.push(backdrop);

  setTimeout(() => {
    backdrop.addEventListener('click', (e) => {
      if (e.target !== backdrop) {
        return;
      }

      // @ts-ignore
      closeButton.click();
    });
  }, 1000);
};
