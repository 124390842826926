<template>
  <div>
    <n-top-screen disable-safe-spacing>
      <n-dialog />
      <n-container
        preset="top"
        class="search-header-container"
      >
        <n-nav-bar
          :title="title"
          shadowless
        />
        <n-layout
          class="search-inputs-container"
          :spacing-y="false"
          :spacing-bottom="departureOnly"
        >
          <n-grid>
            <n-column :span="6">
              <div class="input-group">
                <div class="input-wrapper">
                  <n-button-fake-input
                    :loading="effectiveLoadingType === 'from'"
                    :selected="fromAddress != null"
                    :is-placeholder="!fromAddress"
                    left-icon="pin"
                    :is-using-current-location="isUsingCurrentLocation"
                    @click="!effectiveLoadingType && openSheet('from')"
                  >
                    {{ fromLabel }}
                  </n-button-fake-input>
                  <n-button-fake-input
                    v-if="!departureOnly"
                    :loading="effectiveLoadingType === 'to'"
                    :selected="toAddress != null"
                    :is-placeholder="!toAddress"
                    left-icon="dot"
                    :is-to-address="true"
                    @click="!effectiveLoadingType && openSheet('to')"
                  >
                    {{ toLabel }}
                  </n-button-fake-input>
                </div>
                <div
                  v-if="!departureOnly"
                  class="switch"
                >
                  <div
                    class="swap-button-container"
                    @click="switchAddresses"
                  >
                    <n-icon
                      name="arrows-swap"
                      color="grey-dark"
                    />
                  </div>
                </div>
              </div>
            </n-column>
          </n-grid>
        </n-layout>

        <!-- Dropdown options section -->
        <n-layout
          v-if="!departureOnly"
          :top-gap="2"
          :spacing-y="false"
        >
          <n-grid>
            <n-column :span="6">
              <dropdown-options
                :button-text="`${$t('main.searchHeader.departure')}: ${timeLabel}`"
                @text-click="openDatetimePicker"
              >
                <template #seats>
                  <slot name="seats" />
                </template>
                <template #charge>
                  <slot name="charge" />
                </template>
              </dropdown-options>
            </n-column>
          </n-grid>
        </n-layout>
      </n-container>
      <n-layout :spacing-y="false">
        <slot />
      </n-layout>
    </n-top-screen>
    <template v-if="datePickerOpen">
      <n-datetime-picker
        user-role="driver"
        :title="$t('c.searchHeader.departureTime')"
        :is-open="datePickerOpen"
        :only-time="datePickerType === 'time'"
        :time-selected="departureTime"
        :display-start-time-options="false"
        @datetimeChange="onDatetimePickerChanged($event)"
        @dateTimePickerClosed="onDatetimePickerClosed()"
      />
    </template>
    <address-search-sheet
      ref="searchSheet"
      :title="$t(`c.searchHeader.where${sheetType}`)"
      :input-label="$t(`c.searchHeader.${sheetType}`)"
      @input="onAddressInput"
      @select="onAddressSelect"
      @error="onAddressError"
    />
  </div>
</template>

<script>
import AddressSearchSheet from '@/components/shared/addressSearchSheet';
import DropdownOptions from '@/components/shared/dropdownOptions';

import { format } from '@/vendor/date-fns';
import { differenceInMinutes, isToday } from 'date-fns';

export default {
  components: {
    AddressSearchSheet,
    DropdownOptions
  },
  props: {
    fromAddress: {
      type: Object,
      default: null,
    },
    fromAddressLabel: {
      type: String,
      default: null,
    },
    toAddress: {
      type: Object,
      default: null,
    },
    time: {
      type: Date,
      required: false,
    },
    datePickerType: {
      type: String,
      default: 'datetime',
    },
    departureOnly: {
      type: Boolean,
      default: false,
    },
    showDepartureTimeTooltip: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    loadingType: {
      type: String,
      default: null,
    },
    disableSwitch: {
      type: Boolean,
      default: false,
    },
    isUsingCurrentLocation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sheetType: null,
      internalLoadingType: null,
      departureTime: this.time,
      datePickerOpen: false
    };
  },
  computed: {
    effectiveLoadingType() {
      return this.loadingType || this.internalLoadingType;
    },
    departureWidth() {
      if (this.$slots.options) {
        return 6 / (this.$slots.options.length + 1);
      }
      return 6;
    },
    toLabel() {
      return !this.toAddress
        ? this.$t('c.searchHeader.fields.destination')
        : this.toAddress.completeAddress;
    },
    fromLabel() {
      if (this.fromAddressLabel) {
        return this.fromAddressLabel;
      }
      return !this.fromAddress
        ? this.$t('c.searchHeader.fields.departure')
        : this.fromAddress.completeAddress;
    },
    timeLabel() {
      if (!this.departureTime) {
        return this.$t('c.searchHeader.selectTime');
      }
      if (this.isWithinNext5Minutes(this.departureTime)) {
        return this.$t('c.searchHeader.now');
      }
      if (isToday(this.departureTime)) {
        return this.$t('c.searchHeader.today') + ' ' + this.formatTime(this.departureTime);
      }
      return this.formatDatetime(this.departureTime);
    }
  },
  watch: {
    departureTime(value) {
      this.$emit('departure-time', value);
    }
  },
  methods: {
    openSheet(type) {
      if (this.departureOnly) {
        return;
      }

      if (this.sheetType !== type) {
        this.$refs.searchSheet.clear();
      }

      this.sheetType = type;
      this.$refs.searchSheet.open();
    },
    onAddressInput(adr) {
      this.internalLoadingType = null;
      adr.sheetType = this.sheetType;
      this.$emit('address-selected', adr);
    },
    onAddressSelect() {
      this.internalLoadingType = this.sheetType;
    },
    onAddressError() {
      this.internalLoadingType = null;
    },
    switchAddresses() {
      this.$emit('switch-addresses');
    },
    setDepartureTime(time) {
      this.departureTime = time;
    },
    openDatetimePicker() {
      this.datePickerOpen = true;
    },
    onDatetimePickerChanged({ dateTime }) {
      if (!dateTime) {
        return;
      }
      this.setDepartureTime(dateTime);
    },
    onDatetimePickerClosed() {
      this.datePickerOpen = false;
    },
    isWithinNext5Minutes(datetime) {
      return differenceInMinutes(datetime, new Date()) <= 5;
    },
    formatDatetime(datetime) {
      return format(datetime, this.datePickerType === 'time' ? 'HH:mm' : 'd. MMM HH:mm');
    },
    formatTime(datetime) {
      return format(datetime, 'HH:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
.search-inputs-container {
  position: relative;
  width: 100%;
}

.input-group {
  position: relative;
  width: 100%;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.input-field {
  width: 100%;
}

.switch {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.swap-button-container {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border: 1px solid var(--color-grey-dark);
  border-radius: 50%;
  z-index: 2;
}

.help-margin-extra {
  margin-left: 0.6em;
}

.search-header-container {
  padding-bottom: 22px;
}

.datetime-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.datetime-display {
  display: inline-flex;
  gap: 5px;
}

.datetime-label {
  min-height: 25px;
  display: inline-flex !important;
  align-items: center !important;
}
</style>
