<template>
  <n-text v-if="readonly" preset="sub" color="grey-dark">
    <i18n path="c.terms.readonly">
      <template v-slot:termsLink><a class="link" @click="openLink($t('c.terms.termsUrl'))">{{$t('c.terms.termsLink')}}</a></template>
      <template v-slot:conditionsLink><a class="link" @click="openLink($t('c.terms.conditionsUrl'))">{{$t('c.terms.conditionsLink')}}</a></template>
    </i18n>
  </n-text>
  <n-switch v-else class="span-6" v-model="valueModel">
    <i18n path="c.terms.terms" tag="div" class="">
      <template v-slot:termsLink><a class="link" @click="openLink($t('c.terms.termsUrl'))">{{$t('c.terms.termsLink')}}</a></template>
      <template v-slot:conditionsLink><a class="link" @click="openLink($t('c.terms.conditionsUrl'))">{{$t('c.terms.conditionsLink')}}</a></template>
    </i18n>
  </n-switch>
</template>
<script>
import { isExpoApp } from '@/device';
import nativeBridge from '@/native-bridge';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueModel: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
  },
  methods: {
    openLink(url) {
      if (isExpoApp) {
        return nativeBridge.send.openInAppUrl(url);
      }
      return window.open(url, '_blank');
    }
  },
}
</script>
