<template>
  <div class="select-overlay">
    <div class="country-container">
      <div class="icon">
        {{ value.icon }}
      </div>
      <span class="country-code">
        {{ value.text }}
      </span>
    </div>
    <n-icon
      class="chevron-icon"
      name="chevron"
      outline
      color="grey-medium"
      size="sm"
    />
    <select @change="onChange($event)">
      <option v-if="placeholder">
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :selected="value && value.id === option.id"
      >
        {{ option.icon }} {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'IconDropdown',
  props: {
    selectedOption: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
  },
  methods: {
    onChange(event) {
      const item = this.options.find(
        (option) => option.id === event.target.value
      );
      this.$emit('input', item);
    },
  },
};
</script>
<style scoped lang="scss">
.select-overlay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid var(--color-neutral-500);
  border-radius: 10px;
  background-color: #fbfbfb;
  position: relative;
  font-family: Arial, sans-serif;
  width: 100%;
}
.select-overlay select {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.country-container {
  display: flex;
  align-items: center;
  gap: 5px
}
.icon {
  font-size: 18px;
}
.country-code {
  font-size: var(--text-md-font-size);
  color: var(--color-grey-darker);
}
.chevron-icon {
  transform: rotate(180deg);
}
</style>
