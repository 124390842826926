<template>
<div class="toggle-container">
  <div
    class="toggle"
    :class="[this.state_class, shadow ? 'shadow' : '']"
    @click.self="onClick"
    >
    <div
      class="draggable"
      @mousedown.prevent="dragStart"
      :style="style">
    </div>
  </div>
  <label v-if="$slots.default" :class="{'center': centerText}">
    <n-text style="display: flex; align-items: center;" preset="sub" :color="state ? 'accent' : textColor" :class="[state_class]">
      <slot />
    </n-text>
  </label>
</div>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    centerText: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      width: 100,
      state: this.value,
      pressed: 0,
      position: 0
    }
  },
  mounted() {
    this.toggle(this.state);
  },
  computed: {
    style() {
      return {
        transform: `translateX(${this.pos_percentage})`
      }
    },
    pos_percentage() {
      return `${this.position / this.width * 100}%`
    },
    state_class() {
      if (this.state) {
        return 'active'
      }
    }
  },
  watch: {
    position() {
      this.state = this.position >= 50
    },
    value() {
      this.state = this.value;
      this.toggle(this.state);
    },
  },
  methods: {
    onClick() {
      this.toggle(!this.state)
      this.emit()
    },
    toggle(state) {
      this.state = state
      this.position = !state
        ? 0
        : 100
    },
    dragging(e) {
      const pos = e.clientX - this.$el.offsetLeft
      const percent = pos / this.width * 100
      this.position = percent <= 0
        ? 0
        : percent >= 100
          ? 100
          : percent
    },
    dragStart(e) {
      this.startTimer()
      window.addEventListener('mousemove', this.dragging)
      window.addEventListener('mouseup', this.dragStop)
    },
    dragStop() {
      window.removeEventListener('mousemove', this.dragging)
      window.removeEventListener('mouseup', this.dragStop)
      this.resolvePosition()
      clearInterval(this.$options.interval)
      if (this.pressed < 30) {
        this.toggle(!this.state)
      }
      this.pressed = 0
      this.emit()
    },
    startTimer() {
      this.$options.interval = setInterval(() => {
        this.pressed++
      }, 1)
    },
    resolvePosition() {
      this.position = this.state
        ? 100
        : 0
    },
    emit() {
      this.$emit('input', this.state)
    }
  }
}
</script>

<style lang="scss" scoped>

.toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.shadow {
    box-shadow: 0px 3px 6px var(--color-shadow);
  }
  label {
    width: calc(100% - 15px - 20px);
    margin-left: 15px;
    color: var(--color-grey-dark);
    &.center {
      line-height: 22px;
    }
  }
}

.toggle {
  width: 41px;
  height: 22px;
  box-sizing: border-box;
  background: var(--color-grey-dark);
  border-radius: 200px;
  padding: 2px;
  transition: background 0.6s;

  .draggable {
    width: 18px;
    height: 18px;
    background: var(--color-white);
    border-radius: 100%;
    // box-shadow: 0px 3px 10px rgba(0,0,0, 0.6);
    transform: translateX(0%);
    transition: transform 0.05s ease-in-out;
  }

  &.active {
    background: var(--color-accent);
    transition: background 0.6s;
  }
}

</style>
