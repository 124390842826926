import axios from '@/vendor/axios';
import { format } from '@/vendor/date-fns';
import { objectToFormData, stripNullValues, getDeviceFingerprint } from '@/vendor/utils';

export default {
  signInWithOtp({ phone, otp }) {
    const fingerprint = getDeviceFingerprint();

    return axios
      .post('/auth/login-otp', { phone, otp, fingerprint })
      .then((response) => response.data);
  },

  /**
   * @param {integer} playerId
   */
  signOut(playerId) {
    const fingerprint = getDeviceFingerprint();

    return axios
      .post('/v5/logout', {
        player_id: playerId,
        fingerprint,
      })
      .then((response) => response.data);
  },

  /**
   * @param {void}
   */
  delete() {
    return axios
      .delete('/v5/me', {})
      .then((response) => response.data);
  },

  getConversations() {
    return axios
      .get(`/conversations`)
      .then((response) => response.data);
  },

  getUserConversation(userId, axiosOptions = {}) {
    return axios
      .get(`/conversations/${userId}`, axiosOptions)
      .then((response) => response.data);
  },
  /**
   * @param {string} message 
   * @param {int} userId 
   * @returns 
   */
    newConversationMessage(message, userId) {
    return axios
      .post(`/conversations/${userId}`, {message})
      .then((response) => response.data);
  },

  /**
   * @param {string} message 
   * @param {int} userId 
   * @returns 
   */
    broadcastMessageToPassengers(driverTripId, message) {
      return axios
        .post(`/trip-conversations/${driverTripId}`, {message})
        .then((response) => response.data);
  },

  /**
   * @param {void}
   */
  fetchPublicProfile(id) {
    return axios
      .get(`/v3/user/profile/${id}`)
      .then((response) => response.data);
  },

  /**
   * @param {Object} query
   */
  readNotifications(query) {
    return axios
      .post('/v3/me/notifications/read', query)
      .then((response) => response.data);
  },

  /**
   * @param {void}
   */
  profileFetch() {
    return axios
      .get('/me')
      .then((response) => response.data);
  },

  /**
   * @param {object} options
   */
  updateBankInformation(data) {
    return axios
      .put('/v5/me/update-bank-information', data)
      .then((response) => response.data);
  },

  /**
   * @param {string} playerId
   */
  registerDevice(playerId) {
    return axios
      .post('/me/register-device', { player_id: playerId, })
      .then((response) => response.data);
  },

  /**
   * @param {Date} from
   * @param {Date} to
   */
  profileTransactionsFetch(from, to) {
    return axios
      .get(`/me/transactions?from=${format(from)}&to=${format(to)}`)
      .then((response) => response.data);
  },

  fetchNotifications(page) {
    return axios
      .get(`v4/notifications/all?page=${page}`)
      .then((response) => response.data);
  },

  markAllNotificationsAsRead() {
    return axios
      .patch('v3/notifications/mark-all')
      .then((response) => response.data);
  },

  markAsRead(id) {
    return axios
      .patch('v3/notifications/read', { id })
      .then((response) => response.data);
  },

  generateDepositLink(paymentMethod, amount, tripId = null) {
    return axios
      .post('v3/balance/payment/link', {
        tripId: tripId,
        method: paymentMethod,
        amount,
      })
      .then((response) => response.data);
  },

  processPayout() {
    return axios
      .post('v3/balance/payout')
      .then((response) => response.data);
  },
  /** end deprecated */
  checkPhone(phone) {
    return axios.post(`auth/check`, {phone: phone})
      .then(response => response.data);
  },
  patchProfile(data) {
    return axios.patch(`me`, data)
      .then(response => response.data);
  },
  patchProfileForm(data) {
    const formData = objectToFormData(data, {});
    formData.append("_method", "patch")
    return axios.postForm(`me`, formData)
      .then(response => response.data);
  },
   markAllMessagesAsRead() {
    return axios
      .patch('v3/messages/mark-all')
      .then((response) => response.data);
  },
  requestAuthOTP({ type, value, recaptcha }) {
    console.assert(['email', 'phone'].includes(type), {
      type,
      msg: 'Is not a valid otp type',
    });
    const fingerprint = getDeviceFingerprint();

    return axios
      .post(
        `auth/otp`,
        { [type]: value, fingerprint },
        {
          headers: {
            'X-Recaptcha-Response': recaptcha,
          },
        }
      )
      .then((response) => response.data);
  },
  requestUpdatePhoneOTP(phone_number, recaptcha) {
    const fingerprint = getDeviceFingerprint();

    return axios
      .post(
        `update-phone/otp`,
        { phone_number, fingerprint },
        {
          headers: {
            'X-Recaptcha-Response': recaptcha,
          },
        }
      )
      .then((response) => response.data);
  },
  requestPhoneOTP(phoneNumber, recaptcha) {
    const fingerprint = getDeviceFingerprint();

    return axios
      .post(
        `phone/otp`,
        { phoneNumber, fingerprint },
        {
          headers: {
            'X-Recaptcha-Response': recaptcha,
          },
        }
      )
      .then((response) => response.data);
  },
  registerUser({phoneNumber, otp, language, prospectId}) {
    const fingerprint = getDeviceFingerprint();

    return axios.post(`sign-up/register`, {phoneNumber, otp, language, fingerprint, prospectId})
      .then(response => response.data);
  },
  profilePhoneUpdate(data) {
    return axios.patch(`v5/me/update-phone`, data);
  },
  addVoucher(code) {
    return axios.post(`v5/me/vouchers`, {code})
      .then(response => response.data);
  },
  createVacation({from_date, to_date}) {
    return axios.post(`me/vacation`, {from_date, to_date})
      .then(response => response.data);
  },
  editVacation({to_date}) {
    return axios.put('me/vacation', {to_date})
      .then(response => response.data);
  },
  deleteVacation() {
    return axios.delete('me/vacation')
      .then(response => response.data);
  },
  getTravelPassOptions() {
    return axios
      .get('/travel-pass-options')
      .then(response => response.data);
  },
  /**
   *
   * @param {UserTravelPassRequest} userTravelPassRequest
   * @returns
   */
  createTravelPass(userTravelPassRequest) {
    const formData = objectToFormData(userTravelPassRequest, {});
    return axios
      .postForm(`/me/travel-passes`, formData, { timeout: 60000,  })
      .then(response => response.data);
  },
  getTravelPasses() {
    return axios
      .get(`/me/travel-passes`)
      .then(response => response.data);
  },
  deleteTravelPass(id) {
    return axios
      .delete(`/me/travel-passes/${id}`)
      .then(response => response.data);
  }
};
