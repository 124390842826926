<template>
  <n-theme
    type="passenger"
    class="view-container"
  >
    <n-scrollable class="scroll-view">
      <n-nav-bar
        :title="
          pickingReturnTime
            ? $t('main.demand.tripOptions.returnTitle')
            : $t('main.demand.tripOptions.title')
        "
        class="top-nav"
      />
      <n-layout>
        <trip-options
          v-model="options"
          theme="passenger"
          class="span-6"
          :from="formattedFrom"
          :to="formattedTo"
          :default-time="time"
          :by="by"
          :is-return-trip="pickingReturnTime"
        />
        <n-button
          size="lg"
          :loading="creating"
          block
          @click="create"
          :text-uppercase="false"
        >
          {{
            $t(
              `main.demand.tripOptions.${
                pickingReturnTime ? 'returnCreate' : 'create'
              }`
            )
          }}
        </n-button>
      </n-layout>
    </n-scrollable>
    <request-return-trip-dialog ref="requestReturnTripDialog" />
    <n-dialog />
  </n-theme>
</template>

<script>
import constants from '@/constants';
import * as util from '@/vendor/utils';
import commuteApi from '@/api/commute';
import { EventBus } from '@/vendor/events';
import { format } from '@/vendor/date-fns';
import { set } from 'date-fns';
import TripOptions from '@/components/shared/tripOptions';
import RequestReturnTripDialog from '@/dialogs/requestReturnTripDialog';

export default {
  name: 'MainDemandCreateDemandOptions',
  components: {
    TripOptions,
    RequestReturnTripDialog,
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.trip) {
      next({ name: 'main.demand' });
      return;
    }

    next((vm) => {
      if (Array.isArray(vm.times)) {
        vm.options.times = vm.times;
      }
    });
  },
  props: {
    trip: {
      type: Object,
      required: true,
    },
    time: {},
    by: {
      type: String,
      default: 'departure',
    },
    from: {
      type: Object,
      required: false,
    },
    to: {
      type: Object,
      required: false,
    },
    return: {
      type: Boolean,
      default: false,
    },
    times: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      creating: false,
      pickingReturnTime: false,
      options: {},
    };
  },
  computed: {
    formattedFrom() {
      return this.trip.from_stop_id && this.from.stop
        ? this.from.completeAddress
        : this.trip.from_street;
    },
    formattedTo() {
      return this.trip.to_stop_id && this.to.stop
        ? this.to.completeAddress
        : this.trip.to_street;
    },
    defaultReturnTripTime() {
      return set(new Date(), constants.demandDefaultReturnTime);
    },
  },
  methods: {
    create() {
      this.creating = true;

      let recurringTripData = {
        ...this.trip,
        ...util.parseRecurringOptions(this.options),
        planned_time_by: this.options.searchBy.toUpperCase(),
        type: 'OTHER',
      };

      if(this.pickingReturnTime){
        recurringTripData = {
          ...recurringTripData,
          ...util.swapAddressData(this.trip),
        }
      }

      commuteApi.postPassengerRequest(recurringTripData).then(() => {
        if (this.pickingReturnTime) {
          this.redirectToHome(true);
          return;
        }

        this.onSavedSuccessfully();
      });
    },
    onSavedSuccessfully() {
      this.creating = false;
      EventBus.$emit('fetch-recurring-overview');

      if (this.$route.name === 'demand.options.return') {
        this.redirectToHome(true);
        return;
      }

      this.$refs.requestReturnTripDialog.show(
        // yes
        () => {
          // for visual cue only
          setTimeout(() => {
            this.pickingReturnTime = true;
          }, 200);
        },

        // no
        () => {
          this.redirectToHome(true);
        }
      );
    },

    redirectToHome(showSuccessSnack = false) {
      this.$router.resetToHome();
      if (showSuccessSnack) {
        this.$success(this.$t('main.demand.recurringTripCreated'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll-view {
  padding: 70px 0;

  @supports (-webkit-touch-callout: none) {
    padding-top: 100px;
  }

  .top-nav {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}
</style>