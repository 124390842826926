<template>
  <div class="button-fake-input-container">
    <a
      v-bind="$attrs"
      class="button-fake-input"
      :class="{
        'selected': selected,
        'loading': loading,
        'flat-bottom': flatBottom
      }"
      :style="customBorderRadius"
      v-on="$listeners"
    >
      <div
        v-if="leftIcon"
        class="left-icon"
      >
        <n-icon
          :name="leftIcon"
          :custom-width="15"
          :color="getIconColor"
        />
      </div>

      <n-text
        v-if="label"
        class="label color-neutral-600"
        preset="sub"
      >{{ label }}</n-text>
      <n-text
        class="text"
        preset="sub"
        :class="{ 'placeholder': isPlaceholder }"
        :weight="fontWeight"
        :color="textColor"
      >
        <slot />
      </n-text>

      <div
        v-if="rightIcon"
        class="icon-container right-icon"
        @click.stop="$emit('right-icon-clicked')"
      >
        <n-icon
          :name="rightIcon"
          color="grey-dark"
        />
      </div>

      <transition name="fade">
        <div
          v-show="loading"
          class="loading-container"
        >
          <n-spinner />
        </div>
      </transition>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    selected: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    flatBottom: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: null,
    },
    isPlaceholder: {
      type: Boolean,
      default: true
    },
    textColor: {
      type: String,
      default: null,
      description: "Color variable name (e.g. 'accent', 'grey-dark') to be used for text color"
    },
    fontWeight: {
      type: [String, Number],
      default: null,
      description: "Font weight value (e.g. 'bold', '600')"
    },
    isUsingCurrentLocation: {
      type: Boolean,
      default: false,
    },
    isToAddress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customBorderRadius() {
      if (this.borderRadius) {
        return { borderRadius: this.borderRadius };
      }
      return {};
    },
    getIconColor() {
      if (this.isPlaceholder) {
        return 'grey-semilight';
      }
      if (this.leftIcon === 'pin' && this.isUsingCurrentLocation) {
        return 'error';
      }
      if (this.isToAddress) {
        return 'grey';
      }
      return 'accent';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.button-fake-input-container {
  grid-column: 1 / -1;

  .button-fake-input {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background-color: #FBFBFB;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #B7B3AA;
    color: var(--color-grey-dark);
    cursor: pointer;
    flex-direction: row;
    outline: none;
    overflow: hidden;
    padding: 0px 11px;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    @extend .feedback;
    transition: all 0.2s ease-in-out;
    position: relative;

    &.flat-bottom {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .left-icon {
      margin-right: 11px;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .label {
      margin-right: 3px;
      min-width: 25px;
    }

    .text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.15px;

      &.placeholder {
        color: var(--color-grey-medium);
      }

      &:not(.placeholder) {
        color: var(--color-grey-dark);
      }

      &.timepicker {
        color: var(--color-blue-darker);
      }
    }

    svg {
      height: 100%;
      width: 100%;
    }

    &.selected {
      background-color: var(--color-white);
      color: var(--color-accent);
    }

    .loading-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding-right: 1rem;
      background-color: rgba(255, 255, 255, 0.85);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.fade-enter-active {
        transition: opacity .5s;
        transition-delay: .25s;
      }

      &.fade-leave-active {
        transition: opacity .25s;
      }
    }

    &.selected .loading-container {
      background-color: rgba(255, 255, 255, 0.8);

      &.fade-enter-active {
        transition: opacity .75s;
        transition-delay: .5s;
      }
    }
  }
}
</style>
