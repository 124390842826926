<template>
  <n-theme type="driver">
    <component
      :is="renderTrip"
      :driver-trip="driverTrip"
      v-bind="parentProps"
    />
    <cancel-driver-trip-dialog ref="cancelDialog" />
  </n-theme>
</template>

<script>
import i18n from '@/i18n';
import { EventBus } from '@/vendor/events';
import ActiveTrip from '@/components/shared/overview/states/activeTrip';
import PendingTrip from '@/components/shared/overview/states/pendingTrip';
import ScheduledTrip from '@/components/shared/overview/states/scheduledTrip';
import CompletedTrip from '@/components/shared/overview/states/completedTrip';
import ActivityWrapper from '@/components/shared/overview/activityWrapper';
import Rating from '@/components/shared/rating';
import ProfilePicture from '@/components/shared/profilePicture';
import Step from '@/components/shared/overview/parts/step';
import Seats from '@/components/shared/overview/parts/seats';
import constants from '@/constants';
import { mapActions, mapMutations, mapState } from 'vuex';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import { namespacedTypes as namespacedCommute } from '@/store/modules/commute-types';
import CancelDriverTripDialog from '@/dialogs/cancelDriverTripDialog';
import { canStartTrip } from '@/vendor/utils';
import { format, formatRelative } from '@/vendor/date-fns';
import { differenceInMinutes, subMinutes } from 'date-fns';
import config from '@shared/config.json';

export default {
  name: 'DriverTripActivity',
  components: { ActiveTrip, PendingTrip, ScheduledTrip, CompletedTrip, ActivityWrapper, Step, Seats, Rating, ProfilePicture, CancelDriverTripDialog },
  props: {
    driverTrip: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      isBusy: false,
      isExpanded: false,
      offering: false,
      statusColor: 'accent',
      isActivating: false,
    }
  },
  computed: {
    ...mapState({
      driverTrips: state => state.commute.overview.driverTrips,
    }),
    isActiveStateTrip() {
      return this.driverTrip.started_at !== null && this.driverTrip.completed_at === null;
    },
    isPendingActivationStateTrip() {
      const isTripStarted = this.driverTrip.started_at !== null;
      const isTripCompleted = this.driverTrip.completed_at !== null;
      const isTripInStartingGracePeriod = subMinutes(new Date(this.driverTrip.planned_departure), constants.tripActivation.gracePeriodMinutes) < new Date()

      return !isTripStarted && !isTripCompleted && isTripInStartingGracePeriod;
    },
    isScheduledStateTrip() {
      const isTripStarted = this.driverTrip.started_at !== null;
      const isTripCompleted = this.driverTrip.completed_at !== null;
      const pendingActivationDeadline = new Date(this.driverTrip.planned_departure);

      return !isTripStarted && !isTripCompleted && pendingActivationDeadline >= new Date();
    },
    statusText() {
      if (!this.driverTrip.offered) {
        return this.$t(`overview.single.driver.notOffered`);
      }

      if (this.passengersAwaitingApprovalSeats > 0) {
        return this.$tc(`overview.single.driver.requests`, this.passengersAwaitingApprovalSeats);
      }
      if (this.canRateTrip) {
        return this.$t('overview.single.driver.rate');
      }
      return this.$tc(`overview.single.driver.bookedSeats`, this.passengersAcceptedSeats);
    },
    canStartTrip() {
      // eslint-disable-next-line max-len
      return this.driverTrip.completed_at == null && canStartTrip(
        this.driverTrip.planned_departure,
        this.driverTrip.duration,
      ) && this.driverTrip.offered && this.passengersAccepted.length > 0;
    },
    canRateTrip() {
      return this.isHistory;
    },
    isTypePlanned() {
      return this.driverTrip.type === constants.tripTypes.planned;
    },
    isHistory() {
      return this.driverTrip.completed_at !== null;
    },
    showSeats() {
      if (this.isHistory) {
        return false;
      }
      if (this.passengersAwaitingApproval.length > 0) {
        return true;
      }
      if (this.passengersAccepted.length > 0) {
        return true;
      }
      return false;
    },
    minutesToDeparture() {
      return differenceInMinutes(
        new Date(this.driverTrip.planned_departure),
        new Date()
      );
    },
    parentProps() {
      return {
        // data
        isBusy: this.isBusy,
        isExpanded: this.isExpanded,
        offering: this.offering,
        statusColor: this.statusColor,
        isActivating: this.isActivating,

        // computed
        statusText: this.statusText,
        canStartTrip: this.canStartTrip,
        canRateTrip: this.canRateTrip,
        isTypePlanned: this.isTypePlanned,
        isHistory: this.isHistory,
        showSeats: this.showSeats,
        minutesToDeparture: this.minutesToDeparture,
        passengersAccepted: this.passengersAccepted,
        passengersAcceptedSeats: this.passengersAcceptedSeats,
        passengersAwaitingApproval: this.passengersAwaitingApproval,
        passengersAwaitingApprovalSeats: this.passengersAwaitingApprovalSeats,
        passengerPriceSum: this.passengerPriceSum,
        unreadPassengersCanceled: this.unreadPassengersCanceled,
        steps: this.steps,
        hasRatedAll: this.hasRatedAll,
        isQueued: this.isQueued,

        // methods
        hasUnreadNotification: this.hasUnreadNotification,
        openEdit: this.openEdit,
        rateTrip: this.rateTrip,
        openTrip: this.openTrip,
        cancelTrip: this.cancelTrip,
        offerTrip: this.offerTrip,
        openProfile: this.openProfile,
        cancelCommute: this.cancelCommute,
        removeTrip: this.removeTrip,
        ratePassengerTrip: this.ratePassengerTrip,
        offerManuel: this.offerManuel,
        select: this.select,
        openTripDetails: this.openTripDetails,
        activateTrip: this.activateTrip,
        endTripRedirect: this.endTripRedirect,
        endTrip: this.endTrip,
        editTrip: this.editTrip,
      }
    },

    passengersAccepted() {
      // eslint-disable-next-line max-len
      return this.driverTrip.passenger_trips.filter(
        p => p.status === constants.commuteBookingStatus.accepted,
      );
    },
    passengersAcceptedSeats() {
      return this.passengersAccepted
        .reduce((sum, passengerTrip) => sum + passengerTrip.seats, 0);
    },
    passengersAwaitingApproval() {
      // eslint-disable-next-line max-len
      return this.driverTrip.passenger_trips.filter(
        p => p.status === constants.commuteBookingStatus.awaitingApproval,
      );
    },
    passengersAwaitingApprovalSeats() {
      return this.passengersAwaitingApproval
        .reduce((sum, passengerTrip) => sum + passengerTrip.seats, 0);
    },
    passengerPriceSum() {
      return this.driverTrip.passenger_trips
        .filter(t => t.status === constants.commuteBookingStatus.accepted)
        .reduce((sum, trip) => sum + Number(trip.price), 0);
    },
    unreadPassengersCanceled() {
      return this.driverTrip.passenger_trips.filter(
        p =>
          p.status === constants.commuteBookingStatus.canceled &&
          this.hasUnreadNotification(
            'NaboLift\\BookingPassengerCancelled',
            p.id,
          ),
      );
    },
    steps() {
      // ready for expansion
      const steps = [];
      steps.push({
        time: format(new Date(this.driverTrip.planned_departure), 'HH:mm'),
        address: this.driverTrip.from_street,
      });
      steps.push({
        time: this.driverTrip.planned_arrival
          ? format(new Date(this.driverTrip.planned_arrival), 'HH:mm')
          : null,
        address: this.driverTrip.to_street,
      });
      return steps;
    },
    hasRatedAll() {
      return !this.passengersAccepted.some(trip => trip.passenger_rating === null);
    },
    earliestActivationTime() {
      const earliestActivationBeforeDepartureInMinutes = config.activation.earliest_activation_before_departure_in_minutes;

      return subMinutes(
        new Date(this.driverTrip.planned_departure),
        earliestActivationBeforeDepartureInMinutes
      );
    },
    renderTrip() {
      if (this.isActiveStateTrip) {
        return ActiveTrip;
      }
      if (this.isPendingActivationStateTrip) {
        return PendingTrip;
      }
      if (this.isScheduledStateTrip) {
        return ScheduledTrip;
      }
      return CompletedTrip;
    },
    isQueued() {
      const isBeforeEarliestActivationTime = new Date() < this.earliestActivationTime;
      const driverHasActiveTrip = !!this.driverTrips.some(trip => trip.started_at !== null && trip.completed_at === null);

      if ((this.isScheduledStateTrip && isBeforeEarliestActivationTime) || driverHasActiveTrip) {
        return true;
      }

      const tripsScheduled = this.driverTrips
        .filter(trip =>
          trip.started_at === null && trip.completed_at === null
        )
        .sort((a, b) => new Date(a.planned_departure) - new Date(b.planned_departure));

      if (this.isPendingActivationStateTrip || this.isScheduledStateTrip) {
        const isCurrentTripNextInQueue = tripsScheduled.length > 0
          && tripsScheduled[0].id === this.driverTrip.id;

        return !isCurrentTripNextInQueue;
      }

      return false;
    },

  },
  methods: {
    ...mapActions({
      cancelCommute: userTypes.CANCEL_COMMUTE_TRIP,
      removeTrip: namespacedCommute.OVERVIEW_REMOVE_DRIVER_TRIP,
      ratePassengerTrip: namespacedCommute.RATE_PASSENGER_IN_OVERVIEW,
      offerManuel: namespacedCommute.OFFER_TRIP,
      endTrip: namespacedCommute.END_TRIP,
      startTrip: namespacedCommute.START_TRIP,
    }),
    ...mapMutations({
      select: namespacedCommute.SET_OFFER_EDIT,
    }),
    hasUnreadNotification(type, id) {
      return this.$store.getters[userTypes.HAS_UNREAD_NOTIFICATION_FOR_NAME](
        type,
        id,
      );
    },
    openEdit() {
      this.$router.push({
        name: 'edit-trip',
        params: { driverTrip: this.driverTrip }
      });
    },
    rateTrip(passengerTripId, stars) {
      this.ratePassengerTrip({ passengerTripId, rating: stars })
        .catch((error) => {
          if (error?.response?.status === 409) {
            throw new Error("passenger trip already rated");
          }
          throw error;
        });
    },
    openTrip() {

      if (this.isActiveStateTrip) {
        this.$router.push(
          {
            name: 'main.active-trip',
            params: {
              id: this.driverTrip.id
            }
          });
        return;
      }

      let passengerTripId;
      if (this.passengersAwaitingApproval.length > 0) {
        passengerTripId = this.passengersAwaitingApproval[0].id;
      } else if (this.unreadPassengersCanceled.length > 0) {
        passengerTripId = this.unreadPassengersCanceled[0].id;
      }

      this.$router.push({
        name: 'trip-details-driver', params: {
          driverTripId: this.driverTrip.id,
          driverTrip: this.driverTrip,
          scrollToPassengerTripId: passengerTripId
        }
      });
    },
    cancelTrip() {
      this.$refs.cancelDialog.show(
        this.driverTrip.id,
        () => this.$success(this.$t('dialog.cancelDriverTrip.snackbar'))
      );
    },
    async offerTrip() {
      this.offering = true;
      await this.offerManuel(this.driverTrip.id)
      this.offering = false;
    },
    openProfile(id) {
      EventBus.$emit('open-profile', id);
    },
    openTripDetails() {

      let scrollToPassengerTripId = this.passengersAwaitingApproval.length > 0 ? this.passengersAwaitingApproval[0].id : null
      this.$router.push({
        name: 'trip-details-driver',
        params: {
          driverTripId: this.driverTrip.id,
          scrollToPassengerTripId,
        },
      })
    },
    async activateTripRequest() {
      try {
        await this.startTrip(
          this.driverTrip.id
        );
      } catch (error) {
        if (error.handled) {
          return;
        }

        const translationKey = error?.response?.data?.error;

        this.$error(i18n.te(`error.${translationKey}`)
          ? i18n.t(`error.${translationKey}`)
          : null
        );

        return;
      } finally {
        this.isActivating = false;
      }

      this.$success(this.$t('overview.snackbar.tripStarted'));

      this.$router.push({
        name: 'main.active-trip',
        params: {
          id: this.driverTrip.id
        }
      });
    },
    async activateTrip() {
      if (this.isQueued) {
        this.$error(this.$t('overview.snackbar.tripQueued'));
        return;
      }
      if (this.isActivating) {
        return;
      }
      this.isActivating = true;

      if (this.passengersAwaitingApprovalSeats > 0) {
        this.$modal.show('dialog', {
          title: this.$t('overview.dialogs.activateTrip.pendingPassengers.title'),
          text: this.$t('overview.dialogs.activateTrip.pendingPassengers.text'),
          successButton: {
            text: this.$t('overview.dialogs.activateTrip.pendingPassengers.button.confirm'),
            handler: async () => await this.activateTripRequest(),
          },
          cancelButton: {
            text: this.$t('overview.dialogs.activateTrip.pendingPassengers.button.cancel'),
            handler: async () => {
              this.isActivating = false;
            },
          },
          color: 'error',
          cancel: true,
        });
        return;
      }

      if (this.isPendingActivationStateTrip) {
        await this.activateTripRequest();
        return;
      }

      this.$modal.show('dialog', {
        title: this.$t('overview.dialogs.activateTrip.earlyStart.title'),
        text: this.$t('overview.dialogs.activateTrip.earlyStart.text', { time: formatRelative(new Date(this.driverTrip.planned_departure)) }),
        successButton: {
          text: this.$t('overview.dialogs.activateTrip.earlyStart.button.confirm'),
          handler: async () => await this.activateTripRequest(),
        },
        cancelButton: {
            text: this.$t('overview.dialogs.activateTrip.earlyStart.button.cancel'),
            handler: async () => {
              this.isActivating = false;
            },
            color: "error"
          },
          cancel: true,
        theme: "driver"
      })
    },
    endTripRedirect() {
      this.$router.push({
        name: 'main.active-trip',
        params: { forceEndTrip: true }
      });
    },
    editTrip() {
      this.$router.push({
        name: 'edit-trip',
        params: { driverTrip: this.driverTrip }
      });
    },
  }
};
</script>

<style lang="scss" scoped></style>
