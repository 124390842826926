<template>
  <base-modal
    ref="modal"
    @close="onClose"
  >
    <div class="dialog-container">
      <div class="slide-container">
        <div class="header-container">
          <!-- SVG slides with proper crossfade transition -->
          <div class="slides-wrapper">
            <transition name="fade">
              <div class="slide-image" :key="currentSlide">
                <img 
                  :src="require(`@/assets/images/planned-rides/${locale}/slide-${currentSlide + 1}.svg`)" 
                  :alt="`Slide ${currentSlide + 1}`"
                />
              </div>
            </transition>
          </div>
        </div>
        
        <div class="content">
          <h2 class="title">
            {{ $t('plannedRidesDialog.title') }}
            <span class="title-highlight">
              {{ $t('plannedRidesDialog.subtitle') }}
            </span>
          </h2>
          <div class="description">{{ $t('plannedRidesDialog.description') }}</div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import store from '@/store';
import BaseModal from '@/components/core/overlays/BaseModal.vue';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import { namespacedTypes as commuteTypes } from '@/store/modules/commute-types';
import { mapState } from 'vuex';
import { getLanguageWithFallback } from '@/i18n';

export default {
  name: 'PlannedRidesDialog',
  
  components: {
    BaseModal
  },
  
  data() {
    return {
      currentSlide: 0,
      totalSlides: 3,
      slideInterval: null
    };
  },
  
  computed: {
    ...mapState('commute', ['plannedRidesDialogShown']),
    locale() {
      if (!this.$store.getters.userIsLoggedIn) {
        return getLanguageWithFallback(this.$i18n.locale).id.toLowerCase();
      }
      return this.$store.state.user.profile.language.toLowerCase()
    }
  },
  
  mounted() {
    const unwatch = this.$store.watch(
      () => store.state.user.profile?.feature_flags,
      (featureFlags) => {
        if (featureFlags && this.shouldShowDialog()) {
          this.show();
          this.startSlideshow();
          unwatch();
        }
      },
      { immediate: true }
    );
  },
  
  beforeDestroy() {
    this.clearSlideshow();
  },
  
  methods: {
    shouldShowDialog() {
      const hasTransitionFeatureFlag = store.getters[userTypes.HAS_FEATURE_FLAG]('PlannedRidesExperiment');
      return hasTransitionFeatureFlag && !this.plannedRidesDialogShown;
    },

    show() {
      this.$refs.modal.show();
    },
    
    close() {
      this.$refs.modal.close();
    },
    
    onClose() {
      this.$store.commit(commuteTypes.SET_PLANNED_RIDES_DIALOG_SHOWN, true);

      this.clearSlideshow();
    },
    
    startSlideshow() {
      this.clearSlideshow();

      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, 5000);
    },
    
    clearSlideshow() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval);
        this.slideInterval = null;
      }
    },
    
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.totalSlides;
    }
  }
};
</script>

<style scoped>
.dialog-container {
  overflow: hidden;
}

.slide-container {
  position: relative;
  width: 100%;
}

.header-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70.54%; /* (261/370) * 100% */
  background: linear-gradient(180deg, #FFF5E5 0%, #FFD1DE 100%);
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
}

.slides-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent; /* Ensure transparency to show gradient */
}

.slide-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content {
  padding: 0.5rem 1.5rem;
  text-align: center;
  background: white;
  position: relative;
  z-index: 1;
  border-radius: 16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.title {
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #093527;
  margin-bottom: 12px;
}

.title-highlight {
  color: #4285f4;
}

.description {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
  line-height: 1.4;
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>