<template>
  <div class="dropdown-options">
    <!-- Dropdown trigger button -->
    <n-button-fake-input
      :selected="false"
      class="dropdown-toggle"
      :flat-bottom="isOpen"
      left-icon="arrow-right"
      @click="toggleDropdown"
    >
      <span
        class="button-text"
        @click.stop="onButtonTextClick"
      >{{ buttonText }}</span>
      <span
        class="chevron-icon"
        :class="{ 'rotate-icon': isOpen }"
      >
        <n-icon
          name="chevron"
          outline
          color="grey-medium"
          size="sm"
        />
      </span>
    </n-button-fake-input>

    <!-- Dropdown content -->
    <div
      v-if="isOpen"
      class="dropdown-content"
    >
      <div class="seats-container">
        <slot name="seats" />
      </div>
      <div class="charge-container">
        <slot name="charge" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: 'Open'
    },
    initialOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: this.initialOpen
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    onButtonTextClick(event) {
      this.$emit('text-click', event);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-options {
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-text {
  flex: 1;
  text-align: left;
  color: var(--color-blue-darker);
  font-weight: 500;
}

.chevron-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &.rotate-icon {
    transform: translateY(-50%) rotate(0deg);
  }
}

.dropdown-content {
  border: 1px solid #B7B3AA;
  border-top: none;
  border-radius: 0px 0px 10px 10px;
  padding: 18px;
  background-color: var(--color-grey-superlight);
  display: flex;
  align-items: end;
  justify-content: flex-start;
  gap: 8px;
}

.seats-container {
  min-width: 110px;
  flex-shrink: 0;
}

.charge-container {
  flex: 1;
}
</style>
