<template>
  <transition name="slide-up">
    <div @click="dismiss" v-if="visible" class="alert" :class="{ 'alert-success': type === 'success', 'alert-error': type === 'error', 'alert-notify': type === 'notify', 'alert-dismissable': !notDismissible }">
      <n-bottom-safe-spacer>
        <n-layout :spacing-y="false">
          <n-column :span="6">
            <svg-icon name="close" class="close-icon" color="white" v-if="!notDismissible" />
            <n-text preset="sub" color="white">{{description}}</n-text>
            <n-button class="action" v-if="actionFun" @click="callAction" type="outlined">{{actionText}}</n-button>
          </n-column>
        </n-layout>
      </n-bottom-safe-spacer>
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex';
  import { namespace as namespaceApp } from '@/store/modules/app-types';

  export default {
    data() {
      return {
        visible: false,
        timeout: null,
        delay: null,
        type: 'success',
        title: '',
        description: '',
        actionText: '',
        actionFun: null,
        forceDismiss: false,
      };
    },
    computed: {
      ...mapState(namespaceApp, ['statusbarHeight', 'edges']),
      notDismissible() {
        return this.delay === null && !this.forceDismiss;
      }
    },
    methods: {
      callAction(e) {
        this.actionFun();
        setTimeout(() => {
          this.forceDismiss = true
          this.dismiss();
        }, 300);
      },
      show(options) {
        this.type = options.type;
        this.title = options.title;
        this.description = options.description;
        this.delay = options.delay;
        if (options.action) {
          this.actionText = options.action.text;
          this.actionFun = options.action.fun;
        } else {
          this.actionText = null;
          this.actionFun = null;
        }

        this.visible = true;

        if (options.delay !== null) {
          this.timeout = setTimeout(() => {
            this.dismiss();
          }, options.delay);
        }
      },
      dismiss() {
        if (this.notDismissable) {
          return;
        }

        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }

        this.visible = false;
        this.timeout = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

  .slide-up-enter-active, .slide-up-leave-active {
    transition: all .35s cubic-bezier(.84,.04,.33,.91);
  }

  .slide-up-enter, .slide-up-leave-to {
    margin-bottom: -100%;
    opacity: .75;
  }

  .slide-up-enter-to, .slide-up-leave {
    margin-bottom: 0;
    opacity: 1;
  }

  .alert {
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 700;
    width: 100%;
    box-shadow: 0 3px 6px --var(---color-shadow);
    padding: 20px 0;
    text-align: center;
    pointer-events: all;

    &.alert-success {
      background-color: var(--color-accent);
    }

    &.alert-error {
      background-color: var(--color-red);
    }

    &.alert-notify {
      background-color: var(--color-orange);
    }

    &.alert-dismissable {
      cursor: pointer;
    }
  }

  .action {
    margin-top: 20px;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    width: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

</style>
